<template>
    <div>
        <kDialog v-show="showPopup" :title="'Stage Selection'" @close="cancel">
            <p>Select the stage to move the encounter to</p>
            <dropdownlist :data-items="stages"
                          :text-field="'text'"
                          :data-item-key="'id'"
                          :value="selectedValue"
                          :default-item="selectedValue"
                          :popup-settings="{ className: 'dropDownWidthFix'}"
                          @change="onStageSelectionChange">
            </dropdownlist>


            <kDialogActionsbar layout="end">
                <KButton :theme-color="'primary'" @click='confirmChange'>Confirm</KButton>
                <KButton :theme-color="'info'" :fill-mode="'outline'" @click='cancel'>Cancel</KButton>
            </kDialogActionsbar>
        </kDialog>
    </div>
</template>

<script setup>
    import { defineProps, defineEmits, ref, watch} from 'vue';
    import { Dialog as kDialog, DialogActionsBar as kDialogActionsbar } from '@progress/kendo-vue-dialogs';
    import { Button as KButton} from '@progress/kendo-vue-buttons';
    import { DropDownList as dropdownlist } from "@progress/kendo-vue-dropdowns";
    import logger  from "./../../modules/logger";
    import { fetchAPI } from '../../modules/fetchAPI';


    const props = defineProps(['replaceClientWithCoder', 'encounterId', 'workpoolId','currentStage','showDialog']);
    const emit = defineEmits(['changed','cancelled']);
    var stages = [
                    { text: "Audit", id: "Audit" },
                    { text: "QC", id: "QC" },
                    { text: "Client", id: "Client" },
                    { text: "Manager", id: "Manager" }
                ];

    const selectedValue = ref({ text: "Select Stage", id: "select" });
    const showPopup = ref(false);
    var stageIndex = -1;
        
    watch(() => props.showDialog, (newValue) => {   updated(newValue); });

    function updated(newValue){
        showPopup.value = newValue;

        refreshStages();

        if (props.replaceClientWithCoder !== null && props.replaceClientWithCoder) {
            stages[2].text = "Coder";
        }
        stageIndex = stages.findIndex(function (element) {
            return element.id == props.currentStage;
        });

        if (stageIndex >= 0) {
            stages.splice(stageIndex, 1);
        }


    }
     
    function refreshStages() {
        stages.length=0;
        stages = [
            { text: "Audit", id: "Audit" },
            { text: "QC", id: "QC" },
            { text: "Client", id: "Client" },
            { text: "Manager", id: "Manager" }
        ];
        selectedValue.value = { text: "Select Stage", id: "select" };
    }

    function onStageSelectionChange(e){
        selectedValue.value = e.value;
    }

    function toggleDialog() {
        showPopup.value = !showPopup.value;
    }

    function cancel() {
        toggleDialog();
        emit("cancelled");
    }

    function confirmChange() {
        let request = {
            encounterId: props.encounterId,
            workpoolId: props.workpoolId,
            stage: selectedValue.value.id
        }
        fetchAPI('api/Encounter/ChangeStage', request)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
              toggleDialog();
              if (props.replaceClientWithCoder !== null
                && props.replaceClientWithCoder
                && request.stage == 'Client')
              {
                emit("changed", { encounterId: request.encounterId, stage: "Coder", actionResult: data });
              } else {
                emit("changed", { encounterId: request.encounterId, stage: request.stage, actionResult: data });
              }
            })
            .catch(error => logger.error('Error: ' + error))
            .finally(() => {
            });              
    }

</script>
