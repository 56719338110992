<!-- CustomTooltip.vue using Kendo Vue Tooltip v4.0.1 -->
<template>
    <div>
        <Tooltip :anchor-element="anchorElement"
                 :content="renderTooltipContent"
                 :open="open"
                 :position="position"
                 :target-element="targetElement"
                 v-bind="attrs"
                 @open="handleOpen"
                 @close="handleClose">
            <slot></slot> <!-- Render any content inside the tooltip -->
        </Tooltip>
    </div>
</template>

<style>
    .tooltip-description {
        max-width: 50ch; /* Limit the maximum width to 50 characters */
        word-wrap: break-word; /* Ensure words do not break */
        overflow-wrap: normal; /* Allows the use of word wrapping */
        white-space: pre-wrap; /* Preserve whitespace and wraps the text */
    }
</style>

<script setup>
    import { defineProps, h, useAttrs } from 'vue';
    import { Tooltip } from '@progress/kendo-vue-tooltip';

    const props = defineProps({
        anchorElement: {
            type: String,
            default: 'pointer',
            validator: function validator(value) {
                return ['pointer', 'target'].includes(value);
            }
        },
        content: [String, Object, Function],
        open: {
            type: Boolean,
            default: undefined
        },
        position: {
            type: String,
            default: 'auto',
            validator: function validator(value) {
                return ['right', 'left', 'top', 'bottom', 'auto'].includes(value);
            }
        },
        targetElement: {
            type: undefined
        },
        title: {
            type: String
        },          
        description: {
            type: String
        },
    });

    // Use useAttrs to capture any other attributes passed to the component
    const attrs = useAttrs();

    // Function to render tooltip content
    const renderTooltipContent = () => {
        return h('div', [
            h('strong', props.title),
            h('br'),
            h('div', { class: 'tooltip-description' }, props.description)
        ]);
    };

    const handleOpen = () => { };
    const handleClose = () => { };
</script>