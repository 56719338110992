import { createLogger } from 'vue-logger-plugin'
import { fetchAPI } from './fetchAPI'

const isProduction = process.env.NODE_ENV === 'production';

// after hook to send log messages to api endpoint
const ServerLogHook = {
    run: (event) => {
        fetchAPI('/api/Logger/LogMessage', event)
    }
}

const logger = createLogger({
    enabled: true,
    level: isProduction ? 'info' : 'debug',
    afterHooks: [ServerLogHook]
})

// export logger with applied options
export default logger