<template>
    <div class="homeHeaderBar k-mb-3">
        <div class="homeHeaderBarRight buttonSpacers">
            <div class="k-align-content-center">
                <KButton :theme-color="'primary'" @click="editConfiguration()" id="btEdit" v-if="!editable">
                    <i class="fa-solid fa-gear fa-fw" />Customize
                </KButton>
                <KButton :theme-color="'primary'" id="btDone" @click="doneEditing()" v-if="editable">
                    <i class="fa-solid fa-check fa-fw" />Done Customizing
                </KButton>
                <KButton :theme-color="'primary'" id="btAddWidgets" ref="btnAddWidgets"
                         @click="openWidgetLibrary();" v-if="editable" :disabled="libraryOpen">
                    <i class="fa-solid fa-plus fa-fw" />Add Widgets
                </KButton>
            </div>
            <ClientFacilityFilter :widgetType="props.widgetType" @filterchange="onClientFilterChange" />
        </div>
    </div>
    <KTileLayout id="homeTileLayout" v-if="showTileLayout" :columns="numTileColumns" :row-height="250" :auto-flow="'row dense'" :gap="{ rows: 10, columns: 10 }" :items="tiles"
                 :positions="dataItems" @dragover.prevent @dragenter.prevent @reposition="handleReposition"
                 @tileresize="handleResize">
        <!-- Number Card widget -->
        <template v-slot:numberboxheader="{ props }">
            <div class="tileHeader k-text-left">
                <div class="tileHeaderTitleContainer">
                    <CustomTooltip :title="props.tile.headerText"
                                   :description="props.tile.widgetData.description">
                        <span class="tileHeaderTitle" :id="'header' + props.tile.uniqueKey" :title="props.tile.headerText">
                            {{ props.tile.headerText }}
                        </span>
                    </CustomTooltip>
                </div>
                <div class="tileHeaderButtons">

                    <KButton :id="'btnDelete' + props.tile.uniqueKey" class="k-float-right" :size="'small'"
                             :theme-color="'info'" @click="removeTile(props.id)" v-if="editable">
                        <i class="fa-solid fa-close" />
                    </KButton>
                </div>
            </div>
        </template>
        <template v-slot:numberbox="{ props }">
            <NumberBox :key="props.tile.uniqueKey" :widget="props.tile.widgetData" :id="props.tile.id"
                       :settings="props.tile.widgetSettings" @selectionChanged="widgetSettingsChanged"></NumberBox>
        </template>
        <!-- Encounter List widget -->
        <template v-slot:encounterlistheader="{ props }">
            <div class="tileHeader k-text-left">
                <div class="tileHeaderTitleContainer">
                    <CustomTooltip :title="props.tile.headerText"
                                   :description="props.tile.widgetData.description">
                        <span class="tileHeaderTitle" :id="'header' + props.tile.uniqueKey" :title="props.tile.headerText">
                            {{ props.tile.headerText }}
                        </span>
                    </CustomTooltip>
                </div>
                <div class="tileHeaderButtons">
                    <KButtonGroup>
                        <KButton :id="'btnSaveGridConfiguration'" :size="'small'" :theme-color="'primary'"
                                 :fill-mode="'outline'" @click="saveGridConfiguration(props.tile.id)">
                            <i class="fa-light fa-bookmark fa-fw" />Save Grid
                        </KButton>
                        <KButton :id="'btnResetGridConfiguration'" :size="'small'" :theme-color="'primary'"
                                 :fill-mode="'outline'" @click="resetGridConfiguration(props.tile.id)">
                            <i class="fa-light fa-arrow-rotate-left fa-fw" />Reset
                        </KButton>
                    </KButtonGroup>
                    <KButton :id="'btnDelete' + props.tile.uniqueKey" class="btnDelete" :size="'small'"
                             :theme-color="'info'" @click="removeTile(props.id)" v-if="editable">
                        <i class="fa-solid fa-close" />
                    </KButton>
                </div>
            </div>
        </template>

        <template v-slot:encounterlist="{ props }">
            <EncounterList :widget="props.tile.widgetData" :id="props.tile.id" :settings="props.tile.widgetSettings"
                           @gridSaved="gridSaved" @gridReset="gridReset">
            </EncounterList>
        </template>
    </KTileLayout>
    <Transition name="slide-fade">
        <div id="widgetLibraryDiv" v-show="editable && libraryOpen">
            <WidgetLibrary ref="theLibrary" :widgetType="props.widgetType" @includeWidget="addTile" @closeWidgetLibrary="closeWidgetLibrary">
            </WidgetLibrary>
        </div>
    </Transition>
</template>

<style>
    .k-tilelayout-item {
        background-color: white;
    }

    .k-tilelayout-item-header.k-card-header {
        display: flex;
        padding: 5px 10px;
    }

    .tileHeader {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 26px;
    }

    .tileHeaderTitleContainer {
        flex: 1; /* Allow the title container to grow */
        overflow: hidden; 
        margin-right: 10px; /* Space for buttons */
    }

    .tileHeaderTitle {
        color: var(--violet-default);
        font-size: 16px;
        font-weight: 700;
        overflow: hidden; 
        text-overflow: ellipsis; 
        white-space: nowrap; /* Prevent line breaks */
        display: block; /* Ensure it behaves as a block element */
    }

    .tileHeaderButtons {
        display: flex;
        align-items: center;
        margin-left: auto; /* Push buttons to the right */
    }

    .btnDelete {
        margin-left: 5px;
    }

    #homeTileLayout {
        padding: 0 !important;
        min-height: 800px;
    }

    .k-tabstrip .k-tabstrip-items .k-link {
        font-size: 16px;
    }

    .k-tilelayout-item.k-card {
        min-height: 250px !important;
        border: 2px solid var(--purple-200);
    }

    .addedTile {
        border-color: var(--pink-default) !important;
    }

    .k-tilelayout-item-body .k-card-body {
        cursor: grab;
    }

    .homeHeaderBar {
        display: flex;
    }

    .homeHeaderBarLeft {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: flex-start;
    }

    .homeHeaderBarRight {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: flex-end;
    }

    #widgetLibraryDiv {
        position: absolute;
        top: -1px;
        right: -1px;
        width: 500px;
        height: calc(100vh - 150px);
        /* Subtract any number to 'lift' window off the lower edge. */
        vertical-align: top;
        background-color: white;
        border: 1px solid var(--purple-200);
        border-radius: 5px;
        z-index: 900;
    }

    .slide-fade-enter-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.3s ease-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(500px);
    }
</style>

<script setup>
    import { TileLayout as KTileLayout } from '@progress/kendo-vue-layout';
    import { Button as KButton, ButtonGroup as KButtonGroup } from '@progress/kendo-vue-buttons';
    import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
    import NumberBox from '../WidgetLibrary/NumberBox.vue';
    import EncounterList from '../WidgetLibrary/EncounterList.vue';
    import WidgetLibrary from '../WidgetLibrary/WidgetLibrary.vue';
    import InformationDialog from "../common/InformationDialog.vue";
    import ClientFacilityFilter from "../common/ClientFacilityFilter.vue";
    import CustomTooltip from '../common/CustomTooltip.vue'
    import { fetchAPI } from '../../modules/fetchAPI'
    import { ref, inject, provide, computed, onMounted, defineProps, defineExpose, nextTick } from 'vue';

    // Props
    const props = defineProps([
        'widgetType', // myevaluator, vizient
        'loadUserLayout' // When 'true', load the user layout.
    ]);

    const editable = ref(false);
    const libraryOpen = ref(false);
    const dataItems = ref([]);
    const tilesBase = ref([]);
    const userId = ref(null);
    const layoutId = ref(-1);
    const numTileColumns = ref(7);
    const showTileLayout = ref(true);
    const maxWidgetCount = ref(8);
    const isVizient = ref(props.widgetType == 'vizient');
    const infoDialog = inject('$infoDialog');

    // Computed
    const tiles = computed(() => {
        return tilesBase.value.map((el) => ({
            ...el,
            reorderable: editable.value,
            resizable: editable.value && !(el.widgetData.widgetType.id === 'numberbox'),
            class: editable.value ? undefined : 'custom',
        }));
    })

    const layoutData = computed(() => {
        return {
            dataItems: dataItems.value,
            tilesBase: tilesBase.value,
        }
    })

    const userLayout = computed(() => {
        return {
            Id: layoutId.value,
            UserId: userId.value,
            Layoutdata: JSON.stringify(layoutData.value),
        }
    })

    const widgetCount = computed(() => {
        return tilesBase.value.length;
    })

    // Mounted
    onMounted(() => {
        //loadUserLayout(); // Let HomeLayout decide when to load a user layout.
    })

    // Methods
    const loadUserLayout = async () => {
        try {
            var apiUrl = "api/UserLayout/GetUserLayout/" + (isVizient.value ? "true" : "false");
            const response = await fetchAPI(apiUrl, "", "GET");

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const userLayout = await response.json();

            if (userLayout !== null) {
                userId.value = userLayout.userId;
                layoutId.value = userLayout.id;

                if (userLayout.layoutData !== null) {
                    const layout = JSON.parse(userLayout.layoutData);

                    if (layout !== null) {
                        tilesBase.value = layout.tilesBase;
                        dataItems.value = layout.dataItems;
                    }
                }
            }
        } catch (error) {
            console.error('Error retrieving userlayout', error);
        }
    }

    const editConfiguration = function () {
        editable.value = !editable.value;
    }

    const doneEditing = function () {
        editable.value = false;
        libraryOpen.value = false;
    }

    const saveLayout = async function () {
        const response = await fetchAPI("api/UserLayout/SaveUserLayout", userLayout.value, "POST");
        const data = await response.json();
        layoutId.value = data;
    }

    const removeTile = function (id) {
        tilesBase.value = tilesBase.value.filter((el) => el.id !== id);
        dataItems.value = dataItems.value.filter((el) => el.id !== id);

        saveLayout();
    }

    const getMaxTileId = function () {
        if (tilesBase.value.length > 0)
            return Math.max(...tilesBase.value.map((tile) => tile.id));
        else
            return 0;
    }

    const addTile = function (widget) {
        if (widgetCount.value >= maxWidgetCount.value) {
            infoDialog.value.Title = "Add New Widget"
            infoDialog.value.Message = "Current capacity limit reached for user selected widgets. Please remove a widget in order to add a new one.";
            infoDialog.value.Show = true;
            return;
        }

        const newTileId = getMaxTileId() + 1;
        let colSpan = 1, rowSpan = 1;

        if (widget.widgetType.id === "encounterlist") {
            colSpan = numTileColumns.value;
            rowSpan = 2;
        }

        dataItems.value.push({
            col: 1, // col is required by Kendo for reorder and resize to work properly
            colSpan: colSpan,
            rowSpan: rowSpan,
            id: newTileId,
        });

        tilesBase.value.push({
            id: newTileId,
            body: widget.widgetType.id,
            header: widget.widgetType.id + "header",
            headerText: widget.widgetName,
            uniqueKey: "widget" + newTileId,
            widgetData: widget,
            widgetSettings: '',
        });

        saveLayout();

        // Once it's added, temporarily highlight the newly-added tile.
        //setTimeout(() => {
        //    var element = document.querySelector('[aria-describedby="' + newTileId + '"]').querySelector('.k-card');
        //    element.classList.add("addedTile");
        //}, "100");
    }

    const handleReposition = function (e) {
        if (!editable.value)
            return;

        dataItems.value = e.value;

        saveLayout();
    }

    const handleResize = function (e) {
        if (!editable.value)
            return;

        dataItems.value = e.value;

        setTimeout(() => {
            tilesBase.value[e.index].uniqueKey += 1;
        }, 500);

        saveLayout();
    }

    const widgetSettingsChanged = function (selectionData) {
        const data = JSON.parse(selectionData);
        const selectedId = data.id;
        var selectedTile = tilesBase.value.find(({ id }) => id === selectedId);
        selectedTile.widgetSettings = selectionData;

        saveLayout();
    }

    const openWidgetLibrary = function () {
        libraryOpen.value = true;
    }

    const closeWidgetLibrary = function () {
        libraryOpen.value = false;
    }

    const onClientFilterChange = async () => {
        showTileLayout.value = false;
        await nextTick();
        showTileLayout.value = true;
    }

    const saveGridId = ref(null);
    const resetGridId = ref(null);

    provide('saveGridId', computed(() => saveGridId.value));
    provide('resetGridId', computed(() => resetGridId.value));

    const saveGridConfiguration = (id) => {
        saveGridId.value = id;
    }

    const resetGridConfiguration = (id) => {
        resetGridId.value = id;
    }

    const gridSaved = () => {
        // Reset the watched value so that subsequent changes are registered.
        saveGridId.value = 0;
    }

    const gridReset = () => {
        // Reset the watched value so that subsequent changes are registered.
        resetGridId.value = 0;
    }

    // Expose
    defineExpose({ loadUserLayout, doneEditing });

</script>
