import { createRouter, createWebHistory } from 'vue-router'
import EventListView from '../views/EventListView.vue'
import AboutView from '../views/AboutView.vue'
import LogoutView from '../views/LogoutView.vue'
import HomeLayoutView from '../views/HomeLayoutView.vue'
import LoginView from '../views/LoginView.vue'
import EncounterListWidgetDetail from '../views/EncounterListWidgetDetail.vue'

import MainHeader from '../components/Layouts/MainHeader.vue'
import PlainHeader from '../components/Layouts/PlainHeader.vue'

import { useCommonStore } from '../store/useCommonStore'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'HomeLayout',
            components: { default: HomeLayoutView, HeaderBar: MainHeader }
        },
        {
            path: '/about',
            name: 'about',
            components: { default: AboutView, HeaderBar: MainHeader }
        },
        {
            path: '/logout',
            name: 'logout',
            components: { default: LogoutView, HeaderBar: PlainHeader }
        },
        {
            path: '/login',
            name: 'login',
            components: { default: LoginView, HeaderBar: MainHeader }
        },
        {
            path: '/encounterListWidgetDetail',
            name: 'EncounterListWidgetDetail',
            components: { default: EncounterListWidgetDetail, HeaderBar: MainHeader },
            props: route => ({
                widgetId: route.query.widgetId,
                tileId: route.query.tileId,
                startDate: route.query.startDate,
                endDate: route.query.endDate,
                resultType: route.query.resultType
            })
        },
        {
            path: '/encounterListWidgetDetailPlain',
            name: 'EncounterListWidgetDetailPlain',
            components: { default: EncounterListWidgetDetail, HeaderBar: PlainHeader },
            props: route => ({
                widgetId: route.query.widgetId,
                tileId: route.query.tileId,
                startDate: route.query.startDate,
                endDate: route.query.endDate,
                resultType: route.query.resultType
            })
        },
    ],
})

router.beforeEach((to, from, next) => {
    if (localStorage.getItem("token") != null || to.name.toLowerCase() === 'login' || to.name.toLowerCase() === 'logout') {
       // Continue to page.
        next()
    } else {
        // Not logged in, redirect to logout.
        next({ name: 'logout' })
    }
})
export default router