<template>
    <KDialog v-show="infoDialog.Show" :width="500" :title="infoDialog.Title" @close="cancel" class="k-text-right buttonSpacers">
        <div id="informationMessageDiv">
            <p id="informationMessage">{{ infoDialog.Message }}</p>
            <KButton :theme-color="'primary'" @click='cancel'>{{ infoDialog.OkText }}</KButton>
        </div>
    </KDialog>
</template>

<style>
    #informationMessageDiv {
        width: 100%;
    }
    #informationMessage {
        text-align: left;
        width: 100%;
        padding: 20px;
    }
</style>

<script setup>
    import { defineEmits, inject } from 'vue';
    import { Dialog as KDialog } from '@progress/kendo-vue-dialogs';
    import { Button as KButton } from '@progress/kendo-vue-buttons';

    const infoDialog = inject('$infoDialog');
    const emit = defineEmits(['closed']);

    function cancel() {
        infoDialog.value.Show = false;
        emit("closed");
    }
</script>
