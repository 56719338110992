<template>
    <div class="numberBox k-h-full k-d-flex k-flex-col">
        <div>
            <DateDropDown @change="({ selectedTimePeriod, startDate, endDate }) => onDateSelectionChanged({ selectedTimePeriod, startDate, endDate })"
                          @settingDates="onSettingDates()" @showNumericValue="({ rValue }) => onShowNumericValue({ rValue })"
                          :period="setTimePeriod" :setdates="setupDates" :isLimitedDate="isLimitedDate"></DateDropDown>
        </div>
        <div class="k-h-full k-w-full k-d-flex k-justify-content-center k-align-items-center" @click="openDetails(false)">
            <span class="numeric-display" ref="numberSpan" v-show="showNumericValue === true && hasError === false">{{ result }}</span>
            <i class="fa-regular fa-triangle-exclamation widget-error-icon" v-show="hasError === true" :title="errorText"></i>
            <KLoader :type="'infinite-spinner'" v-show="showNumericValue === false && hasDatesSet === true" />
            <!-- Reserved for possible future use.
            <div class="divTileButtonsLeft">
                <KButton :theme-color="'info'" :size="'small'" :fill-mode="'outline'" :class="'k-button-noborder'"
                    @click="refreshTile()"><i class="fa-solid fa-arrows-rotate"></i></KButton>
            </div>
            -->
            <div class="divTileButtons">
                <KButton :theme-color="'info'" :size="'small'" :fill-mode="'outline'" :class="'k-button-noborder'"
                         @click="openDetails(true)"><i class="fa-solid fa-arrow-up-right-from-square"></i></KButton>
            </div>
        </div>
    </div>
</template>

<style>
    .numberBox:hover .numeric-display {
        color: var(--blue-default);
    }

    .divTileButtons {
        position: absolute;
        right: 5px;
        bottom: 5px;
    }

    .divTileButtonsLeft {
        position: absolute;
        left: 5px;
        bottom: 5px;
    }

        .divTileButtons button,
        .divTileButtonsLeft button {
            color: var(--gray-300);
        }

    .title-display {
        font-size: 13px;
        font-weight: 400;
    }

    .numeric-display {
        cursor: pointer;
        color: var(--violet-default);
    }

    .number-display3 {
        font-size: 75px;
    }

    .number-display4 {
        font-size: 63px;
    }

    .number-display5 {
        font-size: 51px;
    }

    .number-display6 {
        font-size: 41px;
    }

    .number-display7 {
        font-size: 33px;
    }
</style>

<script setup>
    import { defineEmits, defineProps, onBeforeMount, onMounted, ref } from 'vue'
    import DateDropDown from "../common/DateDropDown.vue"
    import { Button as KButton } from '@progress/kendo-vue-buttons';
    import { fetchAPI } from '../../modules/fetchAPI';

    const props = defineProps(['title', 'state', 'widget', 'result', 'id', 'settings']);
    const emit = defineEmits(['selectionChanged']);

    const isLimitedDate = ref(false);
    const result = ref(0);
    const numberSpan = ref(null);
    var setTimePeriod = ref('');
    var setupDates = ref({ start: null, end: null });
    const selectedTimePeriod = ref(null);
    const startDate = ref(null);
    const endDate = ref(null);
    const refreshData = ref(false);
    var showNumericValue = ref(false);
    var hasDatesSet = ref(false);
    var hasError = ref(false);
    var errorText = ref('');

onBeforeMount(() => {
    if (props.settings !== undefined && props.settings !== '') {
        var settingObject = JSON.parse(props.settings);

        if (settingObject.selectedTimePeriod !== undefined && settingObject.selectedTimePeriod !== '') {
            setTimePeriod.value = settingObject.selectedTimePeriod;
        }

        if (settingObject.startdate !== undefined 
            && settingObject.startdate !== ''
            && settingObject.enddate !== undefined 
            && settingObject.enddate !== '') {
            setupDates.value = { start: settingObject.startdate, end: settingObject.enddate };
            hasDatesSet.value = true;
        }
    }

    if (props.widget !== undefined && props.widget !== '') {
        var widgetObject = props.widget;

        isLimitedDate.value = props.widget.isLimitedDate;

        if (widgetObject !== null && widgetObject.isLimitedDate && widgetObject.timePeriod !== null) {
            setTimePeriod.value = widgetObject.timePeriod;
        }
    }
});

    onMounted(() => {
    });

    const submitData = (props, selectedTimePeriod, startDate, endDate) => {
        let resultDataType = props.widget.dataSourceType.id == "StoredProcedure" ? "ScalarValue" : "Count"

        let request = {
            WidgetId: props.widget.id,
            TileId: props.id,
            TimePeriod: selectedTimePeriod,
            StartDate: startDate,
            EndDate: endDate,
            IsDetail: false,
            DataSourceForeignId: props.widget.DataSourceForeignId,
            Connection: "",
            UserId: "",
            ResultDataType: resultDataType,
            RefreshData: true
        };

        fetchAPI("api/Widget/GetWidgetResult", request)
            .then(response => {
                if (!response.ok) {
                    hasError.value = true; //this hides the number and shows error icon
                    showNumericValue = true; //this hides the spinner
                    errorText.value = 'Results failed to load. Please contact our Support Team.';
                    throw new Error(errorText.value);
                }
                return response.json();
            })
            .then(data => {
                if (data.result === '') {
                    errorText.value = `Results failed to load. Please contact our Support Team. (Error ID: ${data.logMessageId})`
                    hasError.value = true; //this hides the number and shows error icon
                    showNumericValue = true; //this hides the spinner
                }
                else {
                    result.value = data.result;
                    var numberClass = 'number-display3';
                    if (result.value) {
                        switch (result.value.toString().length) {
                            case 1:
                            case 2:
                            case 3:
                                numberClass = 'number-display3'; break;
                            case 4:
                                numberClass = 'number-display4'; break;
                            case 5:
                                numberClass = 'number-display5'; break;
                            case 6:
                                numberClass = 'number-display6'; break;
                            default:
                                numberClass = 'number-display7'; break;
                        }
                    }
                    numberSpan.value.classList.add(numberClass);

                    showNumericValue.value = true;
                }

            })
            .catch(error => {
                hasError.value = true;
                errorText.value = 'Results failed to load. Please contact our Support Team.'
                console.error('There was an error!', error)
            });

        return false;
    }

    function onDateSelectionChanged({ selectedTimePeriod: stp, startDate: sd, endDate: ed }) {
        selectedTimePeriod.value = stp;
        startDate.value = sd;
        endDate.value = ed;
        refreshData.value = true;
        hasDatesSet.value = true;

        const selectionData = JSON.stringify({ selectedTimePeriod: stp, startdate: sd, enddate: ed, id: props.id });
        emit('selectionChanged', selectionData);
        submitData(props, stp, sd, ed);
    }

    function onShowNumericValue({ rValue: rV }) {
        showNumericValue.value = rV;
    }

    function openDetails(newTab) {
        const baseUrl = window.location.origin;
        const url = `${baseUrl}/encounterListWidgetDetail` +
            (newTab ? 'Plain' : '') +
            `?widgetId=${encodeURIComponent(JSON.stringify(props.widget.id))}` +
            `&widgetName=${encodeURIComponent(props.widget.widgetName)}` +
            `&tileId=${encodeURIComponent(props.id)}` +
            `&timePeriod=${encodeURIComponent(selectedTimePeriod.value)}` +
            `&startDate=${encodeURIComponent(startDate.value)}` +
            `&endDate=${encodeURIComponent(endDate.value)}`;

        if (newTab) {
            const popup = window.open(url, "_blank");
        }
        else {
            window.open(url, '_self');
        }
        event.stopPropagation(); /* Prevent any other click events, such as the div underneath, from firing. */
    }

    function onSettingDates() {
        hasDatesSet.value = false;
    }

</script>
