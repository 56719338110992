<template>
    <KFieldWrapper>
        <KLabel :class="'k-form-label'" :id="labelId" :editor-id="id" :editor-valid="valid" :editor-disabled="disabled">
            {{ label }}
            <span v-if="props.required" class="formRequired">*</span>
        </KLabel>
        <div class="k-form-field-wrap" v-show="!props.disabled">
            <KRadioGroup :name="name" :label="label" :valid="valid" :value="value" :checked="value" :layout="layout"
                :data-items="dataComputed" :id="id" :disabled="disabled" @change="handleChange" />
            <KError v-if="showValidationMessage">
                {{ validationMessage }}
            </KError>
            <KHint v-else>{{ hint }}</KHint>
        </div>
        <div class="k-form-field-wrap" v-show="props.disabled">
            {{ valueDisplay }}
        </div>
    </KFieldWrapper>
</template>

<script setup>
import { FieldWrapper as KFieldWrapper } from "@progress/kendo-vue-form";
import { Error as KError, Hint as KHint, Label as KLabel } from "@progress/kendo-vue-labels";
import { RadioGroup as KRadioGroup } from "@progress/kendo-vue-inputs";
import { computed, ref, defineProps, defineEmits, onMounted } from 'vue';

const emit = defineEmits(['change', 'blur', 'focus']);

// Props
const props = defineProps({
    name: String,
    touched: Boolean,
    label: String,
    validationMessage: String,
    hint: String,
    id: String,
    disabled: Boolean,
    valid: {
        type: Boolean,
        default: true
    },
    layout: String,
    data: Object,
    textField: String,
    keyField: String,
    value: {
        type: String,
        default: null
    }
});

const valueDisplay = ref('');

onMounted(() => {
    // Get the value to display when disabled.
    props.data.forEach((d) => {
        if (d[props.keyField] === props.value) {
            valueDisplay.value = d[props.textField];
        }
    })
})

// Computed
const showValidationMessage = computed(() => {
    return props.touched && props.validationMessage;
})

const showHint = computed(() => {
    return !showValidationMessage.value && props.hint;
})

const hintId = computed(() => {
    return showHint.value ? '${props.id}_hint' : "";
})

const errorId = computed(() => {
    return showValidationMessage.value ? '${props.id}_error' : "";
})

const describedBy = computed(() => {
    return '${hintId.value} ${errorId.value}'
})

const labelId = computed(() => {
    return props.label ? '${props.id}_label' : "";
})

// The properties 'value' and 'label' are required by the radio group.
const dataComputed = computed(() => {
    let dataConverted = [];
    props.data.forEach(item => {
        let value = item[props.keyField];
        let label = item[props.textField];
        dataConverted.push({ value, label });
    });
    return dataConverted;
})

// Methods
const handleChange = function (e) {
    emit('change', e);
}

const handleBlur = function (e) {
    emit('blur', e);
}

const handleFocus = function (e) {
    emit('focus', e);
}

</script>
