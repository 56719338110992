<template>
    <div class="logout">
        <h1>Logging out!</h1>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue'
    import { useCommonStore } from '../store/useCommonStore';

    onMounted(() => {
        const store = useCommonStore();
        let classic = store.eValuatorUri;

        store.clearToken();
        sessionStorage.clear();
        window.location.replace(classic + '/Account/MyLogOff');
    });
</script>