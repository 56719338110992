<template>
    <a class="encounterLink" :href="getEncounterLink()" target="props.linkTarget">{{ props.encounterNumber }}</a>
</template>

<style>
a.encounterLink {
    color: var(--violet-default) !important;
    text-decoration: underline !important;
}

a.encounterLink:hover {
    color: var(--blue-default) !important;
}
</style>

<script setup>
import { defineProps } from "vue";
import { useCommonStore } from '../../store/useCommonStore'

const props = defineProps(["serviceProviderId", "clientId", "facilityId", "workpoolId", "encounterStage", "encounterId", "encounterNumber", "linkTarget"]);
const commonStore = useCommonStore();

const getEncounterLink = () => {
    let controllerAction = getControllerAction(props.encounterStage);
    return `${commonStore.eValuatorUri}/SP-${props.serviceProviderId}/Client-${props.clientId}/Fac-${props.facilityId}/WP-${props.workpoolId || 'null'}/WorkPoolEncounter/${controllerAction}/${props.encounterId}?whereFrom=myeval`;
};

const getControllerAction = (stage) => {

    switch (stage?.toLowerCase()) {
        case "dataentry":
            return "Data Entry";
        case "chargeaudit":
        case "chargeauditdraft":
            return "AuditDetail";
        case "audit":
        case "auditdraft":
            return "Audit";
        case "qc":
        case "qcdraft":
            return "QcAudit";
        case "client":
        case "clientdraft":
        case "coder":
        case "coderdraft":
            return "ReviewAudit";
        case "manager":
        case "managerDraft":
            return "ReviewClientResponse";
        case "complete":
        default:
            return "Display";
    }
};

</script>
