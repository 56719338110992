<template>
    <header>
        <router-view class="view" name="HeaderBar">
        </router-view>
    </header>
    <div id="main-container" class="k-d-flex k-w-full k-justify-content-center">
        <div id="content-container">
            <div id="content-inner">
                <router-view>
                </router-view>
            </div>
        </div>
    </div>
    <InformationDialog @closed="infoDialog.ClosedAction" />
    <ConfirmationDialog @confirmed="confirmDialog.ConfirmAction" @cancelled="confirmDialog.CancelAction" />
</template>

<style>
    header {
        height: 50px;
        width: 100vw;
        padding: 0;
        background-color: white;
        position: fixed;
        z-index: 100;
        border-bottom: 2px solid var(--gray-100);
        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }
    #main-container {
        position: relative;
        top: 50px;
        z-index: 1;
    }
    #content-container {
        position: relative;
        height: calc(100vh - 50px);
        width: 100%;
        max-width: 1800px;
        white-space: nowrap;
        overflow-y: auto;
        overflow-x: auto;
    }
    /* This is required to undo any style in content-container that would improperly affect objects below. */
    #content-inner {
        white-space: normal;
        padding: 20px;
    }
</style>

<script setup>
    import { onMounted, ref, provide } from 'vue';
    import { RouterLink, RouterView } from 'vue-router';
    import "./assets/styles/root.css";
    import "./assets/styles/streamline-health.css";
    import "./assets/styles/streamline-health-custom.css";
    import "./assets/fontawesome/css/fontawesome.min.css";
    import "./assets/fontawesome/css/solid.min.css";
    import "./assets/fontawesome/css/regular.min.css";
    import "./assets/fontawesome/css/light.min.css";
    import "./assets/fontawesome/css/thin.min.css";
    import "./assets/styles/style.css"; /* Must be after any files with styles that are modified within. */

    import { fetchAPI } from './modules/fetchAPI'
    import { useCommonStore } from './store/useCommonStore';

    import router from './router';

    const commonStore = useCommonStore();

    setTimeout(() => {
        setInterval(() => {
                fetchAPI('api/account/sessionAlive', "", "GET")
                    .then(response => {
                        if (!response.ok) {
                            router.push({ path: '/logout' })
                        }
                        return response.json();
                    })
                    .then(json => {
                        if (json.data === false) {
                            router.push({ path: '/logout' })
                        }
                    })
                    .catch(error => {
                    });
        }, 5000)
    }, 1000);

    // ----- InformationDialog -----
    // App-level object to control InformationDialog component.
    // Inject into components to control the InformationDialog component.
    // const infoDialog = inject('$infoDialog');
    const infoDialog = ref({
        Title: ref('Information'),
        Message: ref('This is the information displayed in the dialog'),
        OkText: ref('OK'),
        ClosedAction: { type: Function },
        Show: ref(false)
    });
    provide('$infoDialog', infoDialog);
    // ----- End of InformationDialog -----

    // ----- ConfirmationDialog -----
    // App-level object to control ConfirmationDialog component.
    // Inject into components to control the ConfirmationDialog component.
    // const confirmDialog = inject('$confirmDialog');
    const confirmDialog = ref({
        Title: ref('Confirmation'),
        Message: ref('This is the confirmation text displayed in the dialog'),
        ConfirmText: ref('OK'),
        CancelText: ref('Cancel'),
        ConfirmAction: { type: Function },
        CancelAction: { type: Function },
        Show: ref(false)
    });
    provide('$confirmDialog', confirmDialog);
    // ----- End of ConfirmationDialog -----

    // ----- Disable resizeObserver error overlay -----
    // This is necessary when certain objects with automatic resizing are nested, 
    // such as a Kendo grid inside of a Kendo tabstrip.
    const debounce = (callback, delay) => {
      let tid
      return function(...args) {
        const ctx = self
        tid && clearTimeout(tid)
        tid = setTimeout(() => {
          callback.apply(ctx, args)
        }, delay)
      }
    }
    const _ = window.ResizeObserver
    window.ResizeObserver = class ResizeObserver extends _ {
      constructor(callback) {
        callback = debounce(callback, 10)
        super(callback)
      }
    }
    // ----- End of Disable resizeObserver error overlay -----

</script>
