import { defineStore } from 'pinia'
import { ref, computed  } from 'vue'

export const useCommonStore = defineStore('commonStore', () => {
    const token = ref("");

    const storedToken = computed(() => {
        if (token.value === "") {
            token.value = JSON.parse(localStorage.getItem("token"));
        }
        return token.value;
    });

    const userName = computed(() => {
        return storedToken.value.userName;
    });

    const contextUser = computed(() => {
        return storedToken.value.contextUser;
    });

    const firstFacility = computed(() => {
        return storedToken.value.firstFacility;
    });

    const widgetSelectedFacilities = computed(() => {
        return storedToken.value.widgetSelectedFacilities;
    });

    const widgetSelectedFacilitiesVizient = computed(() => {
        return storedToken.value.widgetSelectedFacilitiesVizient;
    });

    const hasVizientFacility = computed(() => {
        return storedToken.value.hasVizientFacility;
    });

    const isAdmin = computed(() => {
        return storedToken.value.isAdmin;
    });

    const isServiceAdmin = computed(() => {
        return storedToken.value.isServiceAdmin;
    });

    const isAuditor = computed(() => {
        return storedToken.value.isAuditor;
    });

    const isCoder = computed(() => {
        return storedToken.value.isCoder;
    });

    const userRoles = computed(() => {
        return storedToken.value.userRoles;
    });

    const eValuatorUri = computed(() => {
        return storedToken.value.eValuatorUri;
    });

    function clearToken() {
        token.value = null;
        localStorage.removeItem("token");
    }

    function userLoggedIn() {
        if (localStorage.getItem("token") === null) {
            return false;
        }
            return true;
    }

    function getCookieValue(name) {
        const r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
        return r ? r[1] : null;

    }
    function setToken(name, newToken) {

        // ref variable token is used to set the jwt token
        // this is essential for user login and logout
        // the value of ref variable token should not be changed.
        if (name.toLowerCase() === "token") {
            token.value = newToken;
        }
        localStorage.setItem(name, JSON.stringify(newToken));
    }

    return { token, userName, contextUser, firstFacility, hasVizientFacility, isAdmin, isServiceAdmin, isAuditor, isCoder, userRoles, eValuatorUri, storedToken, setToken, userLoggedIn, clearToken }
})