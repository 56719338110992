<template>
    <KDialog v-show="confirmDialog.Show" :width="500" :title="confirmDialog.Title" @close="cancel" class="k-text-right buttonSpacers">
        <div id="confirmationMessageDiv">
            <p id="confirmationMessage">{{ confirmDialog.Message }}</p>
            <KButton :theme-color="'primary'" @click='confirm'>{{ confirmDialog.ConfirmText }}</KButton>
            <KButton :theme-color="'info'" :fill-mode="'outline'" @click='cancel'>{{ confirmDialog.CancelText }}</KButton>
        </div>
    </KDialog>
</template>

<style>
    #confirmationMessageDiv {
        width: 100%;
    }
    #confirmationMessage {
        text-align: left;
        width: 100%;
        padding: 20px;
    }
</style>

<script setup>
    import { defineEmits, inject } from 'vue';
    import { Dialog as KDialog } from '@progress/kendo-vue-dialogs';
    import { Button as KButton } from '@progress/kendo-vue-buttons';

    const confirmDialog = inject('$confirmDialog');
    const emit = defineEmits(['confirmed', 'cancelled']);

    function confirm() {
        confirmDialog.value.Show = false;
        emit("confirmed");
    }

    function cancel() {
        confirmDialog.value.Show = false;
        emit("cancelled");
    }

</script>
