<template>
    <div>
        <KDialog v-show="showPopup" :title="'Change Workpool'" @close="cancel">
            <p>Select the workpool to move the encounter to</p>
            <KDropDownList :data-items="workpools"
                           :text-field="'text'"
                           :data-item-key="'actionLink'"
                           :value="selectedValue"
                           :default-item="selectedValue"
                           :popup-settings="{ className: 'dropDownWidthFix'}"
                           @change="onStageSelectionChange">
            </KDropDownList>
            <KDialogActionsbar layout="end">
                <KButton :theme-color="'primary'" @click='confirmChange'>Confirm</KButton>
                <KButton :theme-color="'info'" :fill-mode="'outline'" @click='cancel'>Cancel</KButton>
            </KDialogActionsbar>
        </KDialog>
    </div>
</template>

<script setup>
    import { defineProps, defineEmits, ref, watch } from 'vue';
    import { Dialog as KDialog, DialogActionsBar as KDialogActionsbar } from '@progress/kendo-vue-dialogs';
    import { Button as KButton } from '@progress/kendo-vue-buttons';
    import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
    import logger from "./../../modules/logger";
    import { fetchAPI } from '../../modules/fetchAPI';

    const props = defineProps(['encounterId', 'currentWorkpoolId', 'showDialog']);
    const emit = defineEmits(['moved', 'movecancelled']);
    var workpools = [{ text: "Select Workpool", actionLink: "select" }];

    const selectedValue = ref({ text: "Select Workpool", actionLink: "select" });
    const showPopup = ref(false);

    watch(() => props.showDialog, (newValue) => { updated(newValue); });

    function updated(newValue) {
        showPopup.value = newValue;

        if (showPopup.value === true) {
            refreshWorkpools();
        }

    }

    function refreshWorkpools() {
        workpools.length = 0;
        let request = {
            encounterId: props.encounterId,
            currentWorkpoolId: props.currentWorkpoolId
        }
        fetchAPI('api/Encounter/GetEncounterWorkpools', request)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                workpools = data;
                selectedValue.value = { text: "Select Workpool", actionLink: "select" };
            })
            .catch(error => logger.error('Error: ' + error))
            .finally(() => {
            });
    
    }


    function onStageSelectionChange(e) {
        selectedValue.value = e.value;
    }

    function toggleDialog() {
        showPopup.value = !showPopup.value;
    }

    function cancel() {
        toggleDialog();
        emit("movecancelled");
    }

    function confirmChange() {
        let request = {
            encounterId: props.encounterId,
            currentWorkpoolId: props.currentWorkpoolId,
            changedWorkpoolId: selectedValue.value.actionLink
        }

        fetchAPI('api/Encounter/MoveEncounter', request)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                toggleDialog();
                    emit("moved", { result: data });
            })
            .catch(error => logger.error('Error: ' + error))
            .finally(() => {
            });
    }

</script>
