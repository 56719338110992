<template>
    <div>
        <div class="headerBar">
            <span class="headerBarLeft">
                <h2>{{ widgetName }}</h2>
            </span>
            <span class="headerBarRight">
                <KButtonGroup class="k-float-right">
                    <KButton :id="'btnSaveGridConfiguration'" :size="'small'" :theme-color="'primary'"
                        :fill-mode="'outline'" @click="saveGridConfiguration"><i class="fa-light fa-bookmark fa-fw" />Save
                        Grid
                    </KButton>
                    <KButton :id="'btnResetGridConfiguration'" :size="'small'" :theme-color="'primary'"
                        :fill-mode="'outline'" @click="resetGridConfiguration"><i
                            class="fa-light fa-arrow-rotate-left fa-fw" />Reset
                    </KButton>
                    <KButton :id="'btnExportToExcel'" :size="'small'" :theme-color="'primary'" :fill-mode="'outline'"
                        @click="exportExcel"><i class="fa-light fa-file-arrow-down fa-fw" />Export to Excel
                    </KButton>
                </KButtonGroup>
            </span>
        </div>
        <EncounterGrid ref="theGrid" :mode="'detail'" :widget-id="widgetId" :tile-id="tileId" :time-period="timePeriod"
            :start-date="startDate" :end-date="endDate" :is-detail="isDetail"></EncounterGrid>
    </div>
</template>

<style>
.headerBar {
    background-color: var(--purple-100);
    padding: 7px 20px;
    display: flex;
}

.headerBarLeft {
    width: 50%;
    display: flex;
    align-content: center;
    justify-content: flex-start;
}

.headerBarRight {
    width: 50%;
    display: flex;
    align-content: center;
    justify-content: flex-end;
}
</style>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { Button as KButton, ButtonGroup as KButtonGroup } from '@progress/kendo-vue-buttons';
import { default as EncounterGrid } from '../components/WidgetLibrary/EncounterGrid.vue';

// Data
let theGrid = ref(null);

let widgetId = ref(null);
let widgetName = ref(null);
let tileId = ref(null);
let timePeriod = ref(null);
let startDate = ref(null);
let endDate = ref(null);
let isDetail = ref(null);

const urlParams = new URLSearchParams(encodeURI(window.location.search));

// Lifecycle Methods
onBeforeMount(() => {

    widgetId.value = decodeURIComponent(urlParams.get('widgetId'));
    widgetName.value = decodeURIComponent(urlParams.get('widgetName'));
    tileId.value = decodeURIComponent(urlParams.get('tileId'));
    timePeriod.value = decodeURIComponent(urlParams.get('timePeriod'));
    startDate.value = decodeURIComponent(urlParams.get('startDate'));
    endDate.value = decodeURIComponent(urlParams.get('endDate'));
    isDetail.value = true;

});

// Methods
const saveGridConfiguration = () => {
    theGrid.value?.saveGridConfiguration();
}

const resetGridConfiguration = () => {
    theGrid.value?.resetGridConfiguration();
}

const exportExcel = () => {
    theGrid.value?.exportExcel();
};

</script>
