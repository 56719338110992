<template>
    <EncounterGrid ref="theGrid" :mode="'widget'" :widget-id="widgetId" :tile-id="tileId" :time-period="timePeriod"
        :start-date="startDate" :end-date="endDate" :is-detail="isDetail"></EncounterGrid>
</template>

<style>
</style>

<script setup>
import { ref, defineProps, defineExpose, defineEmits, inject, watch, onBeforeMount } from 'vue';
import { default as EncounterGrid } from '../WidgetLibrary/EncounterGrid.vue';

// Props
const props = defineProps(['widget', 'id', 'settings']);

// Emits
const emit = defineEmits(['gridSaved', 'gridReset']);

// Data
let theGrid = ref(null);

let widgetId = ref(null);
let widgetName = ref(null);
let tileId = ref(null);
let timePeriod = ref(null);
let startDate = ref(null);
let endDate = ref(null);
let isDetail = ref(null);

const urlParams = new URLSearchParams(window.location.search);

// Lifecycle Methods
onBeforeMount(() => {
    widgetId.value = props.widget.id;
    widgetName.value = '';
    tileId.value = props.id;
    timePeriod.value = 'all';
    startDate.value = '1/1/1900';
    endDate.value = '12/31/2099';
    isDetail.value = true;
});

// Inject and Watch
const saveGridId = inject('saveGridId');
const resetGridId = inject('resetGridId');
watch(saveGridId, () => {
    if (saveGridId.value === props.id) { saveGridConfiguration(); }
    return saveGridId;
});
watch(resetGridId, () => {
    if (resetGridId.value === props.id) { resetGridConfiguration(); }
    return resetGridId;
});

// Methods
const saveGridConfiguration = () => {
    theGrid.value?.saveGridConfiguration();
    emit('gridSaved');
}

const resetGridConfiguration = () => {
    theGrid.value?.resetGridConfiguration();
    emit('gridReset');
}

const exportExcel = () => {
    theGrid.value?.exportExcel();
};

// Expose
defineExpose({ saveGridConfiguration, resetGridConfiguration, exportExcel });

</script>
