<template>
    <KFieldWrapper>
        <KLabel :class="'k-form-label'" :editor-id="id" :editor-valid="valid">
            {{ label }}
            <span v-if="props.required" class="formRequired">*</span>
        </KLabel>
        <div class="k-form-field-wrap" v-show="!props.disabled">
            <KDropDownList :style="{ width: '100%' }" :data-items="data" :data-item-key="keyField" :value-field="keyField"
                :text-field="textField" :default-item="defaultItem" :valid="valid" :value-primitive="true"
                :default-value="value" ref="theDdl" :id="id" :disabled="disabled" :required="required"
                @change="handleChange" @blur="handleBlur" @focus="handleFocus" />
            <KError v-if="showValidationMessage">
                {{ validationMessage }}
            </KError>
            <KHint v-else>{{ hint }}</KHint>
        </div>
        <div class="k-form-field-wrap" v-show="props.disabled">
            {{ valueDisplay }}
        </div>
    </KFieldWrapper>
</template>
<script setup>
import { FieldWrapper as KFieldWrapper } from "@progress/kendo-vue-form";
import { Error as KError, Hint as KHint, Label as KLabel } from "@progress/kendo-vue-labels";
import { DropDownList as KDropDownList } from "@progress/kendo-vue-dropdowns";
import { computed, defineProps, defineEmits, watch, ref, onMounted } from 'vue';

// Props
const props = defineProps({
    data: Array,
    textField: String,
    keyField: String,
    touched: Boolean,
    label: String,
    required: Boolean,
    validationMessage: String,
    defaultItem: Object,
    hint: String,
    id: String,
    resetId: String,
    valid: Boolean,
    disabled: Boolean,
    value: {
        type: String,
        default: null
    }
});

const emit = defineEmits(['change', 'blur', 'focus', 'reset']);

const valueDisplay = ref('');
const theDdl = ref(null);

onMounted(() => {
    // Get the value to display when disabled.
    props.data.forEach((d) => {
        if (d[props.keyField] === props.value) {
            valueDisplay.value = d[props.textField];
        }
    })
})

// Computed
const showValidationMessage = computed(() => {
    return props.touched && props.validationMessage;
})

const showHint = computed(() => {
    return !showValidationMessage.value && props.hint;
})

const hintId = computed(() => {
    return showHint.value ? '${props.id}_hint' : "";
})

const errorId = computed(() => {
    return showValidationMessage.value ? '${props.id}_error' : "";
})

// Methods
const handleChange = function (e) {
    emit('change', e);
}

const handleBlur = function (e) {
    emit('blur', e);
}

const handleFocus = function (e) {
    emit('focus', e);
}

watch(() => props.resetId, (id) => {
    // The console will show that this is being called properly.
    // However, there is no way to reset the dropdownlist because the value is bound to a prop, which is immutable.
    //console.log('watch resetId');
    //theDdl.value.value = props.defaultItem[props.valueField];
    emit('reset');
});

</script>
