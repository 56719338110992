<template>
    <div class="k-text-center k-w-full k-m-sm">
        <label>
            <span class="k-label k-form-label">End</span>:
            <DateInput :value="value" :format="format" :id="id" @change="handleChange" />
        </label>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { DateInput } from "@progress/kendo-vue-dateinputs";

const props = defineProps({
    value: Date,
    format: String,
    id: String,
    min: Date,
    max: Date,
    disabled: Boolean,
    label: String,
});
const emit = defineEmits(['change']);

function handleChange(e) {
    emit("change", e);
}
</script>
