<template>
    <div>
        <KDialog width="500px" height="auto" v-if="visibleDialog" :title="formTitle" @close="cancelWidgetForm">
            <div v-if="loading" class="loadingDiv">
                <KLoader :type="'infinite-spinner'" :size="'large'" />
            </div>
            <div v-if="formData">
                <KForm ref="theForm" :initialValues="widget" :ignoreModified="true" :onSubmitclick="submitClick" :validator="validateForm">
                    <KFormElement>
                        <KField :id="'widgetName'" :name="'widgetName'" :label="'Name'" :value="widget.widgetName"
                                :validator="widgetNameValidator" :validationMessage="valMsgWidgetName" :focus="true"
                                :valid="validWidgetName" :max-length="50" :required="true" :component="'myTemplate'">
                            <template v-slot:myTemplate="{ props }">
                                <KFormInput v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                            @focus="props.onFocus" />
                            </template>
                        </KField>
                        <KField :id="'description'" :name="'description'" :label="'Description'" :resizable="'vertical'"
                                :rows="3" :autoSize="true" :validator="descriptionValidator" :max-length="500"
                                :required="true" :component="'myTemplate'">
                            <template v-slot:myTemplate="{ props }">
                                <KFormTextArea v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                               @focus="props.onFocus" />
                            </template>
                        </KField>
                        <KField :id="'widgetTypeId'" :name="'widgetTypeId'" :label="'Type'" :data="formData.widgetTypes"
                                :disabled="props.inEditMode ? true : false" :keyField="'id'" :textField="'displayName'"
                                :onChange="handleWidgetTypeChange" :component="'myTemplate'">
                            <template v-slot:myTemplate="{ props }">
                                <KFormRadioGroup v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                                 @focus="props.onFocus" />
                            </template>
                        </KField>
                        <KField :id="'dataSourceForeignId'" :name="'dataSourceForeignId'" :label="'Data Source'"
                                :disabled="props.inEditMode ? true : false" :data="formData.dataSources"
                                :keyField="'foreignId'" :textField="'displayName'"
                                :default-item="{ displayName: 'Select data source...', foreignId: '0' }"
                                :validator="dataSourceForeignIdValidator" :required="true" :resetId="resetId"
                                :component="'myTemplate'" @reset="resetDataSourceForeignId">
                            <template v-slot:myTemplate="{ props }">
                                <KFormDropDownList v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                                   @focus="props.onFocus" />
                            </template>
                        </KField>
                        <KField :id="'isLimitedDate'" :name="'isLimitedDate'" :label="'Limited Date'"
                                :data="[{ label: 'Disabled', value: 'false' }, { label: 'Enabled', value: 'true' }]"
                                :value="isLimitedDateString" :keyField="'value'" :textField="'label'"
                                :onChange="handleIsLimitedDateChange" :component="'myTemplate'">
                            <template v-slot:myTemplate="{ props }">
                                <KFormRadioGroup v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                                 @focus="props.onFocus" />
                            </template>
                        </KField>
                        <KField :id="'timePeriod'" :name="'timePeriod'" :label="'Time Period'" :data="timePeriods"
                                :keyField="'id'" :textField="'text'" :value="selectedLimitedDateValue"
                                :required="false" :onChange="handleLimitedDateSelectionChange"
                                :component="'myTemplate'">
                            <template v-slot:myTemplate="{ props }">
                                <div v-if="showLimitedDate">
                                    <KFormDropDownList v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                                       @focus="props.onFocus" />
                                </div>
                            </template>
                        </KField>
                        <KField :id="'isVizient'" :name="'isVizient'" :label="'Vizient Widget'"
                                :data="[{ label: 'Disabled', value: 'false' }, { label: 'Enabled', value: 'true' }]"
                                :value="isVizientString" :keyField="'value'" :textField="'label'"
                                :onChange="handleIsVizientChange" :component="'myTemplate'">
                            <template v-slot:myTemplate="{ props }">
                                <div v-if="showVizient">
                                    <KFormRadioGroup v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                                     @focus="props.onFocus" />
                                </div>
                            </template>
                        </KField>
                        <KField :id="'isPrivate'" :name="'isPrivate'" :label="'Access'"
                                :data="[{ label: 'Private', value: 'true' }, { label: 'Public', value: 'false' }]"
                                :value="isPrivateString" :keyField="'value'" :textField="'label'"
                                :onChange="handleIsPrivateChange" :component="'myTemplate'">
                            <template v-slot:myTemplate="{ props }">
                                <KFormRadioGroup v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                                 @focus="props.onFocus" />
                            </template>
                        </KField>
                        <KField :id="'isDefault'" :name="'isDefault'" :label="'Default Widget'" :component="'myTemplate'"
                                :value="widget.isDefault" :onChange="handleIsDefaultChange">
                            <template v-slot:myTemplate="{ props }">
                                <div :class="{ 'hideFormDiv': hideIsDefault }">
                                    <SimpleCheckBox v-bind="props" @change="props.onChange" />
                                </div>
                            </template>
                        </KField>
                        <KField :id="'roleIds'" :name="'roleIds'" :label="'Role Assignment'" :data="formData.roles"
                                :keyField="'id'" :textField="'name'" :required="true" :validator="widgetRoleValidator"
                                :validationMessage="valMsgWidgetRoles" :valid="validWidgetRoles" :onBlur="onBlurWidgetRoles"
                                :component="'myTemplate'">
                            <template v-slot:myTemplate="{ props }">
                                <div :class="{ 'hideFormDiv': hideRoles }">
                                    <KFormMultiSelect v-bind="props" @change="props.onChange" @blur="props.onBlur"
                                                      @focus="props.onFocus" />
                                </div>
                            </template>
                        </KField>
                        <div class="k-text-right buttonSpacers">
                            <KButton :theme-color="'primary'" type="submit" :disabled="!canSave" v-if="!inEditMode">
                                Create Widget
                            </KButton>
                            <KButton :theme-color="'primary'" type="submit" :disabled="!canSave" v-if="inEditMode">
                                Save
                            </KButton>
                            <KButton :theme-color="'error'" :fill-mode="'outline'" type="button" @click="deleteConfirm"
                                     v-if="inEditMode">
                                Delete
                            </KButton>
                            <KButton :theme-color="'info'" :fill-mode="'outline'" type="button"
                                     @click="cancelWidgetForm">
                                Cancel
                            </KButton>
                        </div>
                    </KFormElement>
                </KForm>
            </div>
        </KDialog>
    </div>
    <!--<ConfirmationDialog :title="confirmDeleteTitle" :message="confirmDeleteMessage"
                        :confirmText="confirmDeleteConfirmText" :cancelText="confirmDeleteCancelText" :show="confirmDeleteShow"
                        @confirmed="deleteConfirmed" @cancelled="deleteCancelled">
    </ConfirmationDialog>
    <ConfirmationDialog :title="confirmCancelTitle" :message="confirmCancelMessage"
                        :confirmText="confirmCancelConfirmText" :cancelText="confirmCancelCancelText" :show="confirmCancelShow"
                        @confirmed="cancelConfirmed" @cancelled="cancelCancelled">
    </ConfirmationDialog>-->
</template>

<style>
    .k-dialog {
        max-height: 85%;
    }

    .loadingDiv {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
    }

    .hideFormDiv {
        display: none !important;
    }
</style>

<script setup>
    import { ref, inject, provide, computed, onMounted, defineProps, defineEmits, defineExpose } from 'vue';
    import { fetchAPI } from '../../modules/fetchAPI';
    import { Button as KButton } from '@progress/kendo-vue-buttons';
    import { Dialog as KDialog } from "@progress/kendo-vue-dialogs";
    import { Form as KForm, Field as KField, FormElement as KFormElement } from "@progress/kendo-vue-form";
    import { default as KFormInput } from "../KendoForm/FormInput.vue";
    import { default as KFormTextArea } from "../KendoForm/FormTextArea.vue";
    import { default as KFormDropDownList } from "../KendoForm/FormDropDownList.vue";
    import { default as KFormMultiSelect } from "../KendoForm/FormMultiSelect.vue";
    import { default as KFormRadioGroup } from "../KendoForm/FormRadioGroup.vue";
    import { default as SimpleCheckBox } from "../KendoForm/SimpleCheckBox.vue";

    const props = defineProps(['inEditMode']);
    const emit = defineEmits(['submitted', 'closed', 'loadWidget']);
    const infoDialog = inject('$infoDialog');
    const resetId = ref('0');

    const theForm = ref(null);
    const theFormIsValid = ref(true);
    const formData = ref({});
    const loading = ref(true);
    const widget = ref({});
    const originalWidget = ref({});
    const userCount = ref(null);
    const canSave = ref(false);

    const validWidgetName = ref(true);
    const valMsgWidgetName = ref('');
    const validWidgetRoles = ref(true);
    const valMsgWidgetRoles = ref('');

    let visibleDialog = inject('showWidgetForm');

    const showLimitedDate = ref(false);
    const showVizient = ref(false);
    const hideRoles = ref(false); // Must start false to allow dropdown formatting and validation binding.
    let formTitle = computed(() => {
        return props.inEditMode ? 'Edit Widget' : 'Create Widget';
    });

    const confirmDialog = inject('$confirmDialog');

    const isPrivateString = ref(true);
    const isLimitedDateString = ref(true);
    const isVizientString = ref(true);

    const isDefault = ref(false);
    const hideIsDefault = ref(true);

    const selectedLimitedDateValue = ref({});

    const timePeriods = ref([
        { text: "Today", id: "td" },
        { text: "This Week", id: "tw" },
        { text: "Last Week", id: "lw" },
        { text: "Last Month", id: "lm" },
        { text: "Month To Date", id: "mtd" },
        { text: "Last Quarter", id: "lq" },
        { text: "Year To Date", id: "ytd" },
        { text: "Quarter To Date", id: "qtd" },
        { text: "Q1 This Year", id: "q1this" },
        { text: "Q2 This Year", id: "q2this" },
        { text: "Q3 This Year", id: "q3this" },
        { text: "Q4 This Year", id: "q4this" },
        { text: "Q1 Last Year", id: "q1last" },
        { text: "Q2 Last Year", id: "q2last" },
        { text: "Q3 Last Year", id: "q3last" },
        { text: "Q4 Last Year", id: "q4last" },
        { text: "Last Year", id: "ly" },
        { text: "All", id: "all" },
    ]);

    const getTimePeriod = function (id) {
        return timePeriods.value.find(tp => tp.id === id);
    }

    onMounted(async () => {
        await fetchItems();
        await hasVizientFacility();
        // Default datasources to same as the default widget type.
        formData.value.dataSources = formData.value.numbercardDataSources;

        infoDialog.value.Title = "Error"; // Used throughout this component.
    })

    const handleLimitedDateSelectionChange = function (event) {
        selectedLimitedDateValue.value = getTimePeriod(event.value);
    }

    const handleIsVizientChange = function (e) {
        widget.value.isVizient = (e.value.toLowerCase() === 'true');
        validateFormCustom();
    }

    function loadWidget(newWidget) {
        if (newWidget === null) {
            // If no widget is specified, load a new blank widget.
            widget.value = {
                widgetName: '',
                description: '',
                widgetTypeId: 'numberbox',
                dataSourceForeignId: null,
                dateTypeId: undefined,
                isPrivate: true,
                isLimitedDate: false,
                isDefault: false,
                isVizient: false,
                timePeriod: '',
                roleIds: []
            };
            // Default datasources to same as the default widget type.
            formData.value.dataSources = formData.value.numbercardDataSources;

            // Ensure selectedLimitedDateValue is reset
            selectedLimitedDateValue.value = {};
        }
        else {
            // Otherwise, load the specified widget.
            widget.value = newWidget;
            widget.value.roleIds = [];
            widget.value.widgetRoles.forEach((role) => {
                widget.value.roleIds.push(role.roleId);
            });

            if (widget.value.isLimitedDate) {
                selectedLimitedDateValue.value = getTimePeriod(widget.value.timePeriod);
            }
        }
        // Prep the isPrivate string for use in the radio buttons.
        isPrivateString.value = widget.value.isPrivate.toString();
        // Prep the isLimitedDate string for use in the radio buttons.
        isLimitedDateString.value = widget.value.isLimitedDate.toString();

        isVizientString.value = widget.value.isVizient.toString();

        // Load the local widget with proper values.
        widget.value.widgetTypeId = widget.value.widgetTypeId ? widget.value.widgetTypeId : 'numberbox';
        widget.value.dataSourceTypeId = 'StoredProcedure'; // All widgets have a datasource type of stored procedure for now.

        // Save the original widget upon entry to compare with edited widget.
        originalWidget.value = structuredClone(widget.value);

        // After load and validation binding, hide widget roles if widget is private.
        // A slight delay is required to allow proper setup of the role dropdownlist.
        setTimeout(function () {
            hideRoles.value = widget.value.isPrivate;
            hideIsDefault.value = hideRoles.value;
            showLimitedDate.value = widget.value.isLimitedDate;
        }, 10);

        canSave.value = false;
    }

    const widgetNameValidator = (value) => {
        validWidgetName.value = true;
        widget.value.widgetName = value;
        let returnValue = '';
        if (value != null && value.trim() != '') {
            if (hasNameMatch(value.trim())) {
                returnValue = "Duplicate entry.";
                validWidgetName.value = false;
            }
        }
        else {
            returnValue = "This field is required.";
            validWidgetName.value = false;
        }

        valMsgWidgetName.value = returnValue;
        return returnValue;
    }

    const descriptionValidator = (value) => {
        if (value === undefined || value.trim() === '') {
            return 'This field is required.';
        }
        else {
            return '';
        }
    }

    const handleWidgetTypeChange = (e) => {
        // A change of the widget type requires a different stored procedure datasource.
        // widget.value.dataSourceForeignId = '0';
        // resetId.value = 'dataSourceForeignId';

        // Get the appropriate set of datasources for the selected widget type.
        if (e.value === 'numberbox') {
            formData.value.dataSources = formData.value.numbercardDataSources;
        }
        else if (e.value === 'encounterlist') {
            formData.value.dataSources = formData.value.encounterlistDataSources;
        }
    }

    const dataSourceForeignIdValidator = (value) => {
        if (value === undefined || value === '0' || value === null) {
            return 'This field is required.';
        }
        else {
            return '';
        }
    }

    const resetDataSourceForeignId = () => {
        // A reset has happened, so re-initialize the resetId value.
        resetId.value = '0';
    }

    const handleIsPrivateChange = (e) => {
        widget.value.isPrivate = (e.value.toLowerCase() === 'true');
        hideRoles.value = (e.value.toLowerCase() === 'true');
        hideIsDefault.value = hideRoles.value;
        
        if (widget.value.isPrivate) {
            widget.value.isDefault = false;
        }

        validateFormCustom();
    }

    const handleIsLimitedDateChange = (e) => {
        widget.value.isLimitedDate = (e.value.toLowerCase() === 'true');
        showLimitedDate.value = (e.value.toLowerCase() === 'true');

        // Set default Time Period when Limited Date is selected
        if (widget.value.isLimitedDate) {
            selectedLimitedDateValue.value = { text: "Today", id: "td" };
        }
        else {
            selectedLimitedDateValue.value = {};
        }
    }

    const handleIsDefaultChange = (e) => {
        widget.value.isDefault = e.value;
    }

    const widgetRoleValidator = (value) => {
        let returnValue = '';
        validWidgetRoles.value = true;
        if ((widget.value.isPrivate === 'false' || widget.value.isPrivate === false) && value.length === 0) {
            validWidgetRoles.value = false;
            returnValue = 'This field is required.';
        }
        valMsgWidgetRoles.value = returnValue;
        return returnValue;
    }

    const onBlurWidgetRoles = (e) => {
        widgetRoleValidator(e.value);
    }

    const validateFormCustom = () => {
        let values = {
            widgetName: widget.value.widgetName,
            description: widget.value.description,
            widgetTypeId: widget.value.widgetTypeId,
            dataSourceForeignId: widget.value.dataSourceForeignId,
            isPrivate: widget.value.isPrivate,
            roleIds: widget.value.roleIds
        }
        validateForm(values);
    }

    const validateForm = (values) => {
        // Get a true boolean isPrivate.
        let isPrivate = widget.value.isPrivate.toString() === 'true';

        // Get the form elements.
        widget.value.widgetName = values['widgetName'];
        widget.value.description = values['description'];
        widget.value.widgetTypeId = values['widgetTypeId'];
        widget.value.dataSourceForeignId = values['dataSourceForeignId'];
        widget.value.isPrivate = values['isPrivate'];

        widget.value.widgetRoles = [];
        widget.value.roleIds = [];
        values['roleIds'].forEach((roleId) => {
            widget.value.widgetRoles.push({ WidgetId: widget.value.id, RoleId: roleId });
            widget.value.roleIds.push(roleId);
        });

        if (widgetNameValidator(widget.value.widgetName) == ''
            && !hasNameMatch(widget.value.widgetName)
            && widget.value.widgetName && widget.value.widgetName != ''
            && widget.value.description && widget.value.description != ''
            && widget.value.dataSourceForeignId && widget.value.dataSourceForeignId != '0'
            && (isPrivate || widget.value.roleIds.length > 0
                && (widgetRoleValidator(widget.value.roleIds) == ''))
        ) {
            canSave.value = true;
            theFormIsValid.value = true;
        }
        else {
            canSave.value = false;
            theFormIsValid.value = false;
        }
    }

    const hasNameMatch = (value) => {
        let isPrivate = widget.value.isPrivate.toString() === 'true';
        let foundPublic = false;
        let foundPrivate = false;
        if (!isPrivate && formData.value.publicWidgets != null) {
            formData.value.publicWidgets.forEach(p => {
                if (p.widgetName.toLowerCase() == value.toLowerCase() && widget.value.id !== p.id) {
                    foundPublic = true;
                }
            });
        }
        if (isPrivate && formData.value.privateWidgets != null) {
            formData.value.privateWidgets.forEach(p => {
                if (p.widgetName.toLowerCase() == value.toLowerCase() && widget.value.id !== p.id) {
                    foundPrivate = true;
                }
            });
        }
        // console.log('hasNameMatch foundPrivate: ' + foundPrivate + ', foundPublic: ' + foundPublic);
        return (foundPrivate || foundPublic);
    }

    const fetchItems = async () => {
        try {
            const response = await fetchAPI('api/Widget/FormViewModel', "", "GET");

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            formData.value = await response.json();
            widget.value = formData.value.widget;
            loading.value = false;
        } catch (error) {
            console.error('There was an error!', error);
        }
    }

    const fetchItemsRefresh = async () => {
        try {
            const response = await fetchAPI('api/Widget/FormViewModel', "", "GET");

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            formData.value = await response.json();
        } catch (error) {
            console.error('There was an error!', error);
        }
    }

    const hasVizientFacility = async () => {
        try {
            const response = await fetchAPI('api/UserLayout/HasVizientFacility', "", "GET");

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            showVizient.value = response.json();
        } catch (error) {
            console.error('There was an error!', error);
        }
    }

    const submitClick = (e) => {
        // This is necessary because custom form validation may happen after stock form validation.
        // The stock validation may see the form as invalid, but the custom validation may see the form as valid.
        // Therefore, if the custom validation sees the form as valid, submit the data.
        if (theFormIsValid.value) {
            submitData(e.values);
        }
        else {
            infoDialog.value.Message = 'The form cannot be submitted with these values. Please check and try again.';
            infoDialog.value.Show = true;
        }
    }

    const submitData = (theFormData) => {

        // Get the form data as necessary.
        widget.value.widgetName = theFormData.widgetName;
        widget.value.description = theFormData.description;
        widget.value.widgetTypeId = theFormData.widgetTypeId;
        widget.value.dataSourceForeignId = theFormData.dataSourceForeignId;
        widget.value.isPrivate = (theFormData.isPrivate === true || theFormData.isPrivate?.toLowerCase?.() === 'true'); // Convert to boolean, if necessary.
        widget.value.isLimitedDate = (theFormData.isLimitedDate === true || theFormData.isLimitedDate?.toLowerCase?.() === 'true');
        widget.value.timePeriod = selectedLimitedDateValue.value.id;
        widget.value.roleIds = theFormData.roleIds;
        widget.value.isVizient = (theFormData.isVizient === true || theFormData.isVizient?.toLowerCase?.() === 'true');

        // Get the dateTypeId for the selected dataSourceForeignId.
        if (widget.value.dataSourceForeignId && widget.value.dataSourceForeignId !== '0') {
            if (widget.value.widgetTypeId === 'numberbox') {
                widget.value.dataSourceDateTypeId = formData.value.numbercardDataSources.filter(f => f.foreignId === widget.value.dataSourceForeignId)[0]?.dateTypeId;
            }
            else if (widget.value.widgetTypeId === 'encounterlist') {
                widget.value.dataSourceDateTypeId = formData.value.encounterlistDataSources.filter(f => f.foreignId === widget.value.dataSourceForeignId)[0]?.dateTypeId;
            }
        }
        if (!widget.value.dataSourceDateTypeId) {
            infoDialog.value.Message = 'You must choose a datasource that is compatible with this widget type.';
            infoDialog.value.Show = true;
            return false;
        }

        // Separate the widget roles from the widget object.
        let roleIds = widget.value.roleIds;
        delete widget.value.widgetRoles;
        delete widget.value.roleIds;
        let data = { widget: widget.value, roleIds: roleIds };

        // If a public widget and no roles are assigned, don't submit.
        if (!widget.value.isPrivate && (roleIds === undefined || roleIds === null || roleIds.length === 0)) {
            infoDialog.value.Message = 'A public widget must have at least one role assigned.';
            infoDialog.value.Show = true;
            return false;
        }

        fetchAPI("api/Widget/PostWidget", data)
            .then(response => {
                if (!response.ok) {
                    infoDialog.value.Message = 'Error creating / updating the widget.';
                    infoDialog.value.Show = true;
                    throw new Error('HTTP error! status: ${response.status}');
                }
                if (response.status == 'error') {
                    infoDialog.value.Message = 'Error creating / updating the widget.';
                    infoDialog.value.Show = true;
                }
                return response.json();
            })
            .then(data => {
                if (data.status === 'error') {
                    infoDialog.value.Message = data.message;
                    infoDialog.value.Show = true;
                }
                else {
                    fetchItemsRefresh();
                    emit('submitted');
                    closeForm();
                }
            })
            .catch(error => {
                console.error('There was an error in submitData: ', error);
            });

        return false;
    }

    const deleteConfirm = () => {
        // Get the number of users that are using the widget.
        let data = widget.value;
        fetchAPI("api/Widget/GetWidgetUserCount", data)
            .then((response) => response.json())
            .then(data => {
                userCount.value = data;
                confirmDialog.value = confirmDialogDelete.value;
                confirmDialog.value.Show = true;
            })
            .catch(error => console.error('There was an error in deleteConfirm: ', error));
    }

    const deleteConfirmed = () => {
        let data = widget.value;
        fetchAPI("api/Widget/DeleteWidget", data)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                // Need to refresh the widget information for proper validation of subsequent forms.
                fetchItemsRefresh();
                emit('submitted');
                confirmDialog.value.Show = false;
                closeForm();
                return response.json();
            })
            .catch(error => console.error('There was an error in deleteWidget: ', error));
    }

    const deleteCancelled = () => {
        confirmDialog.value.Show = false;
    }

    const cancelWidgetForm = () => {
        // Compare originalWidget to widget. If the same, no need to confirm, just close.
        if (widget.value.widgetName == originalWidget.value.widgetName
            && widget.value.description == originalWidget.value.description
            && widget.value.widgetTypeId == originalWidget.value.widgetTypeId
            && widget.value.dataSourceForeignId == originalWidget.value.dataSourceForeignId
            && widget.value.isPrivate.toString() == originalWidget.value.isPrivate.toString()
            && widget.value.isLimitedDate.toString() == originalWidget.value.isLimitedDate.toString()
            && widget.value.timePeriod?.toString() == originalWidget.value.timePeriod?.toString()
            && compareArrays(widget.value.roleIds, originalWidget.value.roleIds)
        ) {
            closeForm();
        }
        else {
            cancelConfirm();
        }
    }

    const compareArrays = (array1, array2) => {
        return (array1.length == array2.length) && array1.every(function (element, index) {
            return element === array2[index];
        })
    }

    const cancelConfirm = () => {
        confirmDialog.value = confirmDialogCancel.value;
        confirmDialog.value.Show = true;
    }

    const cancelConfirmed = () => {
        confirmDialog.value.Show = false;
        closeForm();
    }

    const cancelCancelled = () => {
        confirmDialog.value.Show = false;
    }

    const closeForm = () => {
        visibleDialog.value = !visibleDialog.value;
        emit('closed');
    }

    //const delay = ms => new Promise(res => setTimeout(res, ms));
    function delay(ms) {
        var start = new Date().getTime();
        var end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    }

    // Delete confirmation.
    let confirmDeleteMessage = computed(() => {
        return widget.value.widgetName + ' is an active widget for ' + userCount.value + ' user(s). Deleting will permanently remove the widget from the database and from the display for any applicable users. Are you sure you want to proceed?';
    });
    const confirmDialogDelete = ref({
        Title: 'Delete Widget',
        Message: confirmDeleteMessage,
        ConfirmText: 'Yes',
        CancelText: 'Cancel',
        ConfirmAction: deleteConfirmed,
        CancelAction: deleteCancelled,
        Show: false
    });
    // Cancel confirmation.
    const confirmDialogCancel = ref({
        Title: 'Cancel',
        Message: 'Are you sure you want to cancel?',
        ConfirmText: 'Yes',
        CancelText: 'No',
        ConfirmAction: cancelConfirmed,
        CancelAction: cancelCancelled,
        Show: false
    });

    defineExpose({ loadWidget, cancelConfirmed, cancelCancelled, deleteConfirmed, deleteCancelled });

</script>
