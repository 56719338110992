// Base configuration of default encounter grid. 
export const encounterGridBaseColumns = [
    { field: "EncounterNumber", title: "Encounter", headerCell: 'headerTemplate', cell: 'encounterLinkTemplate', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "Workpool", title: "Workpool", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "AuditType", title: "Audit Type", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "ServiceType", title: "Service Type", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "Stage", title: "Stage", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "TotalCharges", title: "Total Charges", type: "number", format: "{0:c2}", filter: "numeric", headerCell: 'headerTemplate', cell: 'cellCurrency', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "Coder", title: "Coder", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "Auditor", title: "Auditor", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "AuditDateTimeCompleted", title: "Audit Complete Date", type: "date", format: "{0:MM/dd/yyyy}", filter: "date", headerCell: 'headerTemplate', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "ReservedTo", title: "Assigned To", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "ImportDate", title: "Import Date", type: "date", format: "{0:MM/dd/yyyy}", filter: "date", headerCell: 'headerTemplate', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "DischargeDate", title: "Discharge Date (IP/OP)/ Date of Service (Profee)", type: "date", format: "{0:MM/dd/yyyy}", filter: "date", headerCell: 'headerTemplate', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "ChangeLikelihood", title: "Requires further review", filter: "numeric", headerCell: 'headerTemplate', cell: 'cellPercentage', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "FinancialImpact", title: "Financial Impact", type: "number", filter: "numeric", headerCell: 'headerTemplate', cell: 'cellFinancial', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "FacilityDisplayName", title: "Facility Name", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "LengthOfStay", title: "LOS", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "AdmitDate", title: "Admit Date", type: "date", format: "{0:MM/dd/yyyy}", filter: "date", headerCell: 'headerTemplate', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "AwaitingCodingChanges", title: "Awaiting Coding Changes", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "Client", title: "Client", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "DischargeDisposition", title: "Discharge Disposition (Final)", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "FinancialCategory", title: "Financial Category", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "FinancialClass", title: "Financial Class", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "HospitalService", title: "Hospital Service", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "Manager", title: "Manager", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "MedicalRecordNumber", title: "Medical Record Number", headerCell: 'headerTemplate', cell: 'cellRightAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "PatientType", title: "Patient Type", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "ReimbursementVariance", title: "Reimbursement Variance", type: "number", filter: "numeric", headerCell: 'headerTemplate', cell: 'cellCurrency', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "RequiresQuery", title: "Requires Query", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "ServicingProvider", title: "Servicing Provider", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "DrgOriginal", title: "(Orig) DRG", headerCell: 'headerTemplate', cell: 'cellRightAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "RelativeExpectedDirectCost",  vizient: "vizient",  title: "Relative Expected Direct Cost", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "OriginalRelativeExpectedDirectCost", vizient: "vizient", title: "Original Relative Expected Direct Cost", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "RelativeExpectedLOS", vizient: "vizient", title: "Relative Expected LOS", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "OriginalRelativeExpectedLOS", vizient: "vizient", title: "Original Relative Expected LOS", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "RelativeExpectedMortality", vizient: "vizient", title: "Relative Expected Mortality", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
    { field: "OriginalRelativeExpectedMortality", vizient: "vizient", title: "Original Relative Expected Mortality", headerCell: 'headerTemplate', cell: 'cellLeftAlign', columnMenu: "myGridColumnMenuTemplate", hidden: true, locked: false },
];

// Configuration of default encounter grid.
export const encounterGridDefaultColumns = [
    { field: "EncounterNumber", width: 250, hidden: false, orderIndex: 0, locked: true },
    { field: "Workpool", width: 250, hidden: false, orderIndex: 1 },
    { field: "AuditType", width: 150, hidden: false, orderIndex: 2 },
    { field: "ServiceType", width: 150, hidden: false, orderIndex: 3 },
    { field: "Stage", width: 150, hidden: false, orderIndex: 4 },
    { field: "TotalCharges", width: 150, hidden: false, orderIndex: 5 },
    { field: "Coder", width: 150, hidden: false, orderIndex: 6 },
    { field: "Auditor", width: 150, hidden: false, orderIndex: 7 },
    { field: "AuditDateTimeCompleted", width: 150, hidden: false, orderIndex: 8 },
    { field: "ReservedTo", width: 150, hidden: false, orderIndex: 9 },
    { field: "ImportDate", width: 150, hidden: false, orderIndex: 10 },
    { field: "DischargeDate", width: 150, hidden: false, orderIndex: 11 },
    { field: "ChangeLikelihood", width: 150, hidden: false, orderIndex: 12 },
    { field: "FinancialImpact", width: 150, hidden: false, orderIndex: 13 },
    { field: "FacilityDisplayName", width: 150, hidden: false, orderIndex: 14 },
    { field: "LengthOfStay", width: 150, orderIndex: 15 },
    { field: "AdmitDate", width: 150, orderIndex: 16 },
    { field: "AwaitingCodingChanges", width: 150, orderIndex: 17 },
    { field: "Client", width: 150, orderIndex: 18 },
    { field: "DischargeDisposition", width: 150, orderIndex: 19 },
    { field: "FinancialCategory", width: 150, orderIndex: 20 },
    { field: "FinancialClass", width: 150, orderIndex: 21 },
    { field: "HospitalService", width: 150, orderIndex: 22 },
    { field: "Manager", width: 150, orderIndex: 23 },
    { field: "MedicalRecordNumber", width: 150, orderIndex: 24 },
    { field: "PatientType", width: 150, orderIndex: 25 },
    { field: "ReimbursementVariance", width: 150, orderIndex: 26 },
    { field: "RequiresQuery", width: 150, orderIndex: 27 },
    { field: "ServicingProvider", width: 150, orderIndex: 28 },
    { field: "DrgOriginal", width: 150, orderIndex: 29 },
    { field: "RelativeExpectedDirectCost", width: 175, orderIndex: 30 },
    { field: "OriginalRelativeExpectedDirectCost", width: 175, orderIndex: 31 },
    { field: "RelativeExpectedLOS", width: 175, orderIndex: 32 },
    { field: "OriginalRelativeExpectedLOS", width: 175, orderIndex: 33 },
    { field: "RelativeExpectedMortality", width: 175, orderIndex: 34 },
    { field: "OriginalRelativeExpectedMortality", width: 175, orderIndex: 35 },
];

// Configuration for 'Coder' role.
export const encounterGridCoderColumns = [
    { field: "EncounterNumber", hidden: false, orderIndex: 0 },
    { field: "AwaitingCodingChanges", hidden: false, orderIndex: 1 },
    { field: "RequiresQuery", hidden: false, orderIndex: 2 },
    { field: "AuditDateTimeCompleted", hidden: false, orderIndex: 3 },
    { field: "FacilityDisplayName", hidden: false, orderIndex: 4 },
    { field: "Workpool", hidden: false, orderIndex: 5 },
    { field: "AuditType", orderIndex: 6 },
    { field: "ServiceType", orderIndex: 7 },
    { field: "Stage", orderIndex: 8 },
    { field: "TotalCharges", orderIndex: 9 },
    { field: "Coder", orderIndex: 10 },
    { field: "Auditor", orderIndex: 11 },
    { field: "ReservedTo", orderIndex: 12 },
    { field: "ImportDate", orderIndex: 13 },
    { field: "DischargeDate", orderIndex: 14 },
    { field: "ChangeLikelihood", orderIndex: 15 },
    { field: "FinancialImpact", orderIndex: 16 },
    { field: "LengthOfStay", orderIndex: 17 },
    { field: "AdmitDate", orderIndex: 18 },
    { field: "Client", orderIndex: 19 },
    { field: "DischargeDisposition", orderIndex: 20 },
    { field: "FinancialCategory", orderIndex: 21 },
    { field: "FinancialClass", orderIndex: 22 },
    { field: "HospitalService", orderIndex: 23 },
    { field: "Manager", orderIndex: 24 },
    { field: "MedicalRecordNumber", orderIndex: 25 },
    { field: "PatientType", orderIndex: 26 },
    { field: "ReimbursementVariance", orderIndex: 27 },
    { field: "ServicingProvider", orderIndex: 28 },
    { field: "DrgOriginal", orderIndex: 29 },
    { field: "RelativeExpectedDirectCost", width: 175, orderIndex: 30 },
    { field: "OriginalRelativeExpectedDirectCost", width: 175, orderIndex: 31 },
    { field: "RelativeExpectedLOS", width: 175, orderIndex: 32 },
    { field: "OriginalRelativeExpectedLOS", width: 175, orderIndex: 33 },
    { field: "RelativeExpectedMortality", width: 175, orderIndex: 34 },
    { field: "OriginalRelativeExpectedMortality", width: 175, orderIndex: 35 }
];

export function SetEncounterGridDefaultColumns(commonStore) {

    // Get the main column definitions.
    var defaultColumns = encounterGridBaseColumns;

    // Determine if any transform column definitions are required.
    var transformColumns = null;
    if (commonStore.isCoder) {
        transformColumns = encounterGridCoderColumns;
    }
    else {
        transformColumns = encounterGridDefaultColumns;
    }

    if (transformColumns) {
        defaultColumns.forEach((defaultCol) => {
            // Get the columns.
            var transformCol = Object.values(transformColumns).find(transformCol => transformCol.field === defaultCol.field);
            // For each key/value in the column transform definition, overwrite the default value.
            Object.keys(transformCol).forEach((key) => {
                if (transformCol.key !== 'field') {
                    if (transformCol[key] === 'remove') {
                        delete defaultCol[key];
                    }
                    else {
                        defaultCol[key] = transformCol[key];
                    }
                }
            });
        });
    }

    if (!commonStore.hasVizientFacility || commonStore.isCoder) {
        defaultColumns = defaultColumns.filter(item => item.vizient != 'vizient');
    }
    
    defaultColumns.sort((a, b) => a.orderIndex - b.orderIndex);

    return defaultColumns;
}

export function SetEncounterGridDefaultSort(commonStore) {
    if (commonStore.isCoder) {
        return "AuditDateTimeCompleted DESC";
    }
    else {
        return "DischargeDate DESC";
    }
}

export function isGridFullWidth(commonStore) {
    if (commonStore.isCoder) {
        return true;
    }
    return false;
}

export function showLockColumnMenu(commonStore) {
    if (commonStore.isCoder) {
        return false;
    }
    return true;
}