<template>
    <KDropDownButton @itemclick="change"
                     :text-field="'text'"
                     :value-field="'actionLink'"
                     :items="dataItem[field]"
                     fillMode="link"
                     shape="square">
        <i class="fa-solid fa-ellipsis-vertical"></i>
    </KDropDownButton>

    <EncounterChangeStage :showDialog="showChangeStage"
                          :encounterId="dataItem['encounterId']"
                          :workpoolId="dataItem['workpoolId']"
                          :replaceClientWithCoder="dataItem['replaceClientWithCoder']"
                          :currentStage="dataItem['stage']"
                          @changed="({encounterId,stage,actionResult})=>onStageChanged({encounterId,stage,actionResult})"
                          @cancelled="onCancel" />
    <MoveEncounter :showDialog="showMoveEncounter"
                   :encounterId="dataItem['encounterId']"
                   :currentWorkpoolId="dataItem['workpoolId']"
                   @moved="({result})=>onMoved({result})"
                   @movecancelled="onMoveCancel" />
</template>

<script setup>
    import { DropDownButton as KDropDownButton } from "@progress/kendo-vue-buttons";
    import { default as MoveEncounter } from '../common/EncounterMoveWorkpool.vue'
    import { default as EncounterChangeStage } from '../common/EncounterChangeStage.vue'
    import { default as ConfirmationDialog } from '../common/ConfirmationDialog.vue'
    import { ref, reactive, defineProps, defineEmits, inject } from "vue";
    import logger from "./../../modules/logger";
    import { fetchAPI } from '../../modules/fetchAPI';

    const unAssignConfirmDialog = inject('$confirmDialog');

    // Data
    const value = reactive({
        text: "Select",
        actionLink: ""
    });
    const actionItems = reactive([
        {
            text: "View",
            actionLink: "view"
        },
        {
            text: "Open",
            actionLink: "open"
        },
        {
            text: "Audit History",
            actionLink: "history"
        },
        {
            text: "Go to Workpool",
            actionLink: "workpool"
        }
    ]);
    let showChangeStage = ref(false);
    let showMoveEncounter = ref(false);
    let selectedRow = ref(Object);

    // Props
    const props = defineProps({
        field: String,
        dataItem: Object
    });

    // Emits
    const emit = defineEmits(['change']);

    // Methods
    const change = function (e) {
        selectedRow.value = props.dataItem;

        const actionMap = {
            'change stage': () => { showChangeStage.value = true; },
            'move encounter': () => { showMoveEncounter.value = true; },
            'unassign user': () => { openUnassignConfirmDialog(); },
        };

        const action = e.item.text.toLowerCase();

        if (actionMap[action]) {
            // If action matches, call the corresponding function
            actionMap[action]();
        } else {
            // Reset flags when an unrecognized action is taken
            showChangeStage.value = false;
            showMoveEncounter.value = false;
            unAssignConfirmDialog.value.Show = false;
            emit('change', e.item);
        }
    };

    const openUnassignConfirmDialog = function () {
        unAssignConfirmDialog.value = {
            Show: true,
            Title: 'Unassign User',
            Message: 'Are you sure you wish to unassign this encounter?',
            ConfirmText: 'Confirm',
            ConfirmAction: unassignConfirmed,
            CancelText: 'Cancel',
            CancelAction: unassignCancelled
        };
    }

    const onStageChanged = function (e) {
        selectedRow.value['stage'] = e.actionResult.stage;
        showChangeStage.value = !showChangeStage.value;
        let rowActionLikField = selectedRow.value[props.field].find((e) => e.text == "Open")
        if (rowActionLikField !== undefined || rowActionLikField != null) {

            selectedRow.value[props.field].find((e) => e.text == "Open").actionLink = e.actionResult.actionLink.actionLink;

            selectedRow.value['reservedTo'] = e.actionResult.encounterReservedTo;

            selectedRow.value['started'] = e.actionResult.encounterStarted;
        }
    }

    const onCancel = function () {
        showChangeStage.value = !showChangeStage.value;
    }

    const onMoved = function (e) {
        showMoveEncounter.value = !showMoveEncounter.value;

        selectedRow.value['workpoolId'] = e.result.workpoolId;
        selectedRow.value['workPool'] = e.result.workPool;
        selectedRow.value['stage'] = e.result.stage;
        selectedRow.value[props.field] = [];

        e.result.encounterActions.forEach((el) => {
            selectedRow.value[props.field].push(el);
        });
    }

    const onMoveCancel = function () {
        showMoveEncounter.value = !showMoveEncounter.value;
    }

    const unassignConfirmed = function (e) {
        let request = {
            encounterId: props.dataItem.encounterId,
            workpoolId: props.dataItem.workpoolId
        }

        fetchAPI('api/Encounter/UnassignUser', request)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                emit('change');
            })
            .catch(error => logger.error('Error: ' + error))
            .finally(() => {
            });
    }

    const unassignCancelled = function () {
        // implement unassign cancelled if needed
    }
</script>