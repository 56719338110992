<template>
    <div class="main">
        <div v-if="showSpinner" class="spinner-border text-primary" role="status" style="width: 10rem; height: 10rem;">
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, provide } from 'vue';
    import { useCommonStore } from '../store/useCommonStore';
    import router from '../router';
    const showSpinner = ref(true);
    const store = useCommonStore();

    let fetchData = () => {
        fetch('api/account/token')
            .then(r => {
                if (!r.ok) {
                    showSpinner.value = false;
                    router.push({ path: '/' })
                }
                return r.json();
            })
            .then(json => {
                showSpinner.value = false;
                store.setToken('token', json);
                router.push({ path: '/' })
            });
    }

    onMounted(() => {
        fetchData();
    })
</script>
