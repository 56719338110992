<template>
    <div id="encounterSearchContainer">
        <div id="searchInput" ref="refSearchInput">
            <KTextBox ref="searchTextBox" v-model="searchText" :placeholder="'Encounter Number'" :input-suffix="'suffix'" @keypress="handleKeyUp">
                <template v-slot:suffix>
                    <KButton :theme-color="'info'" :fill-mode="'outline'" @click="toggleResults">
                        <i class="fa-solid fa-magnifying-glass" />
                    </KButton>
                </template>
            </KTextBox>
        </div>
        <KDialog title="Encounter Search Results" width="80%" height="450px" @close="toggleResults" v-if="show">
            <div class="k-h-full k-d-flex k-flex-col">
                <Grid :class="'theGrid'" :data-items="items" :columns="columns" :resizable="true" :reorderable="true"
                      :sortable="true" :sort="sort" @sortchange="sortChangeHandler" :filter="filter"
                      @filterchange="filterChangeHandler" :skip="skip" :take="take" :pageable="gridPageable"
                      @pagechange="pageChangeHandler" :column-menu="columnMenu" :loader="loader">
                    <template v-slot:headerBlankTemplate="{ props }">
                        <div :class="'k-column-title cellClass'" :title="props.title">{{ props.title }}</div>
                    </template>
                    <template v-slot:headerTemplate="{ props }">
                        <div :class="'k-column-title cellClass'" :title="props.title">{{ props.title }}</div>
                    </template>
                    <template v-slot:cellTemplate="{ props }">
                        <td :colspan="props.colspan" :class="props.class + ' k-grid-edit-command'" :style="props.style">
                            <div :class="'cellClass'" :title="props.dataItem[props.field]">
                                {{ props.dataItem[props.field] }}
                            </div>
                        </td>
                    </template>
                    <template v-slot:actionDropDownCell="{ props }">
                        <td style="display: flex; justify-content: center">
                            <EncounterActionDropDownList :data-item="props.dataItem" :field="props.field"
                                                         @change="(e) => onEncounterActionChange(e)" />
                        </td>
                    </template>
                </Grid>
            </div>
        </KDialog>
    </div>
</template>

<style>
    #encounterSearchContainer {
        min-width: 200px;
    }

    #searchIcon {
        border-radius: 5px;
        margin-right: 2px;
        cursor: pointer;
    }

        #searchIcon:hover {
            background: var(--blue-default);
            color: white;
        }

    #searchResultDiv {
        border: 2px solid var(--gray-200);
    }

    .theGrid {
        height: 100%;
    }

    .cellClass {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: clip;
    }

    .k-table th .k-cell-inner {
        align-items: stretch;
        min-height: 100%;
    }
</style>

<script setup>
    import { computed, ref, onMounted } from "vue";
    import { TextBox as KTextBox } from "@progress/kendo-vue-inputs";
    import { Button as KButton } from '@progress/kendo-vue-buttons';
    import { fetchAPI } from '../../modules/fetchAPI';
    import { Grid } from "@progress/kendo-vue-grid";
    import { process, orderBy, filterBy } from '@progress/kendo-data-query';
    import logger from '../../modules/logger';
    import EncounterActionDropDownList from "./EncounterActionDropDownList.vue";
    import { Dialog as KDialog } from "@progress/kendo-vue-dialogs";

    const isNullOrUndefinedOrEmpty = (value) => value === null || value === undefined || value === "";

    const columns = [
        { field: "encounterActions", title: "", headerCell: 'headerTemplate', cell: "actionDropDownCell", sortable: false, columnMenu: false, width: "40px" },
        { field: "encounterNumber", title: "Encounter", headerCell: 'headerTemplate', cell: 'cellTemplate' },
        { field: "facility", title: "Facility", headerCell: 'headerTemplate', cell: 'cellTemplate' },
        { field: "workPool", title: "Workpool", headerCell: 'headerTemplate', cell: 'cellTemplate' },
        { field: "stage", title: "Stage", headerCell: 'headerTemplate', cell: 'cellTemplate' },
        { field: "reservedTo", title: "Reserved To", headerCell: 'headerTemplate', cell: 'cellTemplate' },
        { field: "started", title: "Started", headerCell: 'headerTemplate', cell: 'cellTemplate' },
    ];

    const searchText = ref(null);
    const result = ref([]);
    const totalRecords = ref(-1);
    const position = ref(0);
    const loader = ref(false);
    const show = ref(false);
    const skip = ref(0);
    const take = ref(5);
    const gridPageable = {
        buttonCount: 4,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true,
    };
    const columnMenu = ref(true);
    const selectedField = ref('selected');
    const expandField = ref('expanded');
    const sort = ref([]);
    const filter = ref();
    const opacity = ref(0);
    const refSearchInput = ref(null);
    const refSearchResultDiv = ref(null);
    let searchTextBox = ref(null);

    const items = computed(() => {
        let dataArray = process(result.value, {
            take: take.value,
            skip: skip.value,
            sort: sort.value,
            filter: filter.value
        });
        return dataArray;
    });

    onMounted(() => {
        window.setTimeout(() => {
            calculatePosition();
        }, 1000);
        searchTextBox.value.focus();
    });

    const getOpacity = () => {
        if (show.value) {
            return 1
        }
        return 0;
    }

    const sortChangeHandler = (e) => {
        sort.value = e.sort;
    };

    const pageChangeHandler = (e) => {
        skip.value = e.page.skip;
        take.value = e.page.take;
    };

    function handleKeyUp(event) {
        // Check if 'Enter' key was pressed and there's any input
        if (event.key === 'Enter') {
            onDisplayResults();
        }
    }

    function toggleResults() {
        show.value = !show.value;
        if (show.value === true) {
            onDisplayResults();
        }
    }

    function onDisplayResults() {
        let hasText = searchText.value != null && searchText.value.trim() !== '';
        if (hasText) {
            let request = "'" + searchText.value + "'";
            loader.value = true;
            show.value = true;
            fetchAPI('api/Encounter/Search', request)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    loader.value = false;
                    totalRecords.value = data.resultCount;
                    result.value = data.results;
                })
                .catch(error => logger.error('Error: ' + error))
                .finally(() => {
                });
        }
        else {
            result.value = [];
        }
    }

    const filterChangeHandler = (e) => {
        filter.value = e.filter;
    };

    const showFilterIcon = (field) => {
        if (filter.value === undefined || filter.value === null) {
            return 'hidden';
        }
        else {
            return 'visible';
        }
    }

    function onEncounterActionChange(e) {
        if (isNullOrUndefinedOrEmpty(e)) {
            onDisplayResults();
        } else {
            let urlLink = e.actionLink;
            window.open(urlLink);
        }
    }

    function calculatePosition() {
        // Access the position of the reference element
        const referenceElement = refSearchInput.value;
        const rect = referenceElement.getBoundingClientRect();

        // Example: Position the target element 20px to the right of the reference element
        position.value = window.innerWidth - rect.right;
    }
</script>