<template>
    <KFieldWrapper>
        <KLabel :class="'k-form-label'" :editor-id="id" :editor-valid="valid" :disabled="disabled" :optional="optional">
            {{ label }}
            <span v-if="props.required" class="formRequired">*</span>
        </KLabel>
        <div class="k-form-field-wrap" v-show="!props.disabled">
            <KTextArea :style="{ width: '100%' }" ref="theTextArea" :id="id" :valid="valid" :default-value="value"
                :placeholder="placeholder" :disabled="disabled" :resizable="resizable" :rows="rows"
                :maxlength="maxLength" :auto-size="autoSize" @input="handleChange" @blur="handleBlur"
                @focus="handleFocus"></KTextArea>
            <KError v-if="showValidationMessage">
                {{ validationMessage }}
            </KError>
            <KHint v-else :direction="'end'"> {{ value.length }} / {{ props.maxLength }} </KHint>
        </div>
        <div class="k-form-field-wrap" v-show="props.disabled">
            {{ valueDisplay }}
        </div>
    </KFieldWrapper>
</template>
<script setup>
import { FieldWrapper as KFieldWrapper } from "@progress/kendo-vue-form";
import { Error as KError, Hint as KHint, Label as KLabel } from "@progress/kendo-vue-labels";
import { TextArea as KTextArea } from "@progress/kendo-vue-inputs";
import { computed, defineProps, defineEmits, ref, onMounted } from 'vue';

const emit = defineEmits(['change', 'blur', 'focus']);

// Props
const props = defineProps({
    touched: Boolean,
    label: String,
    validationMessage: String,
    placeholder: String,
    hint: String,
    id: String,
    valid: Boolean,
    focus: Boolean,
    required: Boolean,
    optional: Boolean,
    disabled: Boolean,
    resizable: String, // vertical, horizontal, both, none
    rows: Number,
    maxLength: Number,
    autoSize: Boolean,
    value: {
        type: String,
        default: ''
    }
});
const theTextArea = ref(null);
const valueDisplay = ref('');

onMounted(() => {
    // Get the value to display when disabled.
    valueDisplay.value = props.value;
    if (props.focus) {
        theTextArea.value.focus();
    }
})

// Computed
const showValidationMessage = computed(() => {
    return props.touched && props.validationMessage;
})

const showHint = computed(() => {
    return !showValidationMessage.value && props.hint;
})

const hintId = computed(() => {
    return showHint.value ? '${props.id}_hint' : "";
})

const errorId = computed(() => {
    return showValidationMessage.value ? '${props.id}_error' : "";
})


// Methods
const handleChange = function (e) {
    emit('change', e);
}

const handleBlur = function (e) {
    emit('blur', e);
}

const handleFocus = function (e) {
    emit('focus', e);
}
</script>
