<template>
    <KFieldWrapper>
        <KLabel :class="'k-form-label'" :editor-id="id" :editor-valid="valid">
            {{ label }}
            <span v-if="props.required" class="formRequired">*</span>
        </KLabel>
        <div class="k-form-field-wrap" v-show="!props.disabled">
            <KMultiSelect :style="{ width: '100%' }" :data-items="data" :data-item-key="keyField" :value-field="keyField"
                          :text-field="textField" :valid="showValid" :id="id" :disabled="disabled" :default-value="value"
                          :filterable="false" :allow-custom="false"
                          :value-primitive="true" :popup-settings="{ className: 'dropDownWidthFix' }" :required="required"
                          @change="handleChange" @blur="handleBlur" @focus="handleFocus" />
            <KError v-if="showValidationMessage">
                {{ validationMessage }}
            </KError>
            <KHint v-else>{{ hint }}</KHint>
        </div>
        <div class="k-form-field-wrap" v-show="props.disabled">
            {{ valueDisplay }}
        </div>
    </KFieldWrapper>
</template>

<script setup>
    import { FieldWrapper as KFieldWrapper } from "@progress/kendo-vue-form";
    import { Error as KError, Hint as KHint, Label as KLabel } from "@progress/kendo-vue-labels";
    import { MultiSelect as KMultiSelect } from '@progress/kendo-vue-dropdowns';
    import { computed, defineProps, defineEmits, ref, watch, onMounted } from 'vue';

    // Multiselect seems to work differently than other objects re: touched. Create our own touched when an onBlur happens.
    const touchedAlt = ref(false);

    const emit = defineEmits(['change', 'blur', 'focus']);

    // Props
    const props = defineProps({
        data: Object,
        textField: String,
        keyField: String,
        touched: Boolean,
        label: String,
        validationMessage: String,
        hint: String,
        id: String,
        reset: String,
        disabled: Boolean,
        valid: Boolean,
        required: Boolean,
        value: {
            type: Array,
            function() {
                return [];
            }
        }
    });
    const valueDisplay = ref('');

    onMounted(() => {
        // Get the value to display when disabled.
        props.data.forEach((d) => {
            props.value.forEach((v) => {
                if (d[props.keyField] === v) {
                    valueDisplay.value += d[props.textField] + ', ';
                }
            })
        })
        // Get rid of last comma and space.
        valueDisplay.value = valueDisplay.value.substring(0, valueDisplay.value.length - 2);

        // Disable the multiselect input field to prevent typing.
        document.getElementById(props.id).readOnly = true;
    })

    // Computed
    const showValid = computed(() => {
        if (!(props.touched || touchedAlt.value)) { return true; }
        return props.valid;
    })

    const showValidationMessage = computed(() => {
        return (props.touched || touchedAlt.value) && props.validationMessage;
    })

    const showHint = computed(() => {
        return !showValidationMessage.value && props.hint;
    })

    const hintId = computed(() => {
        return showHint.value ? '${props.id}_hint' : '';
    })

    const errorId = computed(() => {
        return showValidationMessage.value ? '${props.id}_error' : '';
    })


    // Methods
    const handleChange = function (e) {
        emit('change', e);
    }

    const handleBlur = function (e) {
        touchedAlt.value = true;
        emit('blur', e);
    }

    const handleFocus = function (e) {
        emit('focus', e);
    }

    watch(() => props.reset, (id) => {
        emit('reset');
    });

</script>
