<template>
    <div>
        <Checkbox :id="id" :label="label" :value="value" @change="handleChange"/>
    </div>
</template>
<script setup>
    import { computed, defineProps, defineEmits } from 'vue';
    import { FieldWrapper as KFieldWrapper } from "@progress/kendo-vue-form";
    import { Label as KLabel } from "@progress/kendo-vue-labels";
    import { Checkbox } from "@progress/kendo-vue-inputs";
import { booleanFilterValues } from '@progress/kendo-vue-data-tools';

    const props = defineProps({
        id: String,
        label: String,
        value: Boolean
    })

    const emit = defineEmits(['change']);

    const handleChange = function (e) {
        emit('change', e);
    }
</script>