<template>
    <div>
        <GridColumnMenuSort :column="column" :sortable="sortable" :sort="sort" @closemenu="closeMenu"
            @sortchange="sortChange" />
        <GridColumnMenuFilter :column="column" :filterable="filterable" :filter="filter" :filter-operators="filterOperators" @filterfocus="handleFocus" 
            @closemenu="closeMenu" @expandchange="expandChange" @filterchange="filterChange" />
        <GridColumnMenuItemGroup>
            <GridColumnMenuItem :title="'Columns'" :icon-class="'k-i-columns'" @menuitemclick="onMenuItemClick" />
            <GridColumnMenuItemContent :show="columnsExpanded">
                <div class="k-column-list-wrapper">
                    <form @submit="onSubmit" @reset="onReset">
                        <div class="k-column-list">
                            <div :key="idx" class="k-column-list-item" v-for="(column, idx) in currentColumns">
                                <span>
                                    <input :id="'column-visiblity-show-' + idx"
                                        :class="'k-checkbox k-checkbox-md k-rounded-md'" :type="'checkbox'"
                                        :disabled="!column.hidden && oneVisibleColumn" :checked="!column.hidden"
                                        @click="onToggleColumn(idx)" />
                                    <label :for="'column-visiblity-show-' + idx" :class="'k-checkbox-label'"
                                        :style="{ userSelect: 'none' }">
                                        &nbsp;{{ column.title }}
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div class="k-columnmenu-actions">
                            <KButton :theme-color="'primary'">Save</KButton>
                            <KButton :theme-color="'info'" type="reset">Reset</KButton>
                        </div>
                    </form>
                </div>
            </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
        <GridColumnMenuItemGroup>
            <GridColumnMenuItemContent :show="showLockMenu">
                <div class="k-column-list-wrapper">
                    <div class="k-column-list">
                        <div @click="columnLockUnlock(true)" v-show="!props.locked"
                            :class="['k-column-list-item', props.locked ? 'k-disabled' : '']">
                            <i class="fa-light fa-lock"  /> Lock Column
                        </div>
                        <div @click="columnLockUnlock(false)" v-show="props.locked" 
                            :class="['k-column-list-item', !props.locked ? 'k-disabled' : '',]">
                            <i class="fa-light fa-unlock" /> Unlock Column
                        </div>
                    </div>
                </div>
            </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, defineEmits } from 'vue';
import {
    GridColumnMenuFilter,
    GridColumnMenuSort,
    GridColumnMenuItemGroup,
    GridColumnMenuItemContent,
    GridColumnMenuItem
} from '@progress/kendo-vue-grid';
    import { Button as KButton } from '@progress/kendo-vue-buttons';
    import * as encounterGridConfiguration from '../../modules/encounterGridConfiguration';
import { useCommonStore } from '../../store/useCommonStore';

// Default Grid filter operators:
const filterOperators = {
    'text': [
        { text: 'grid.filterContainsOperator', operator: 'contains' },
        { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
        { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' }
    ],
    'numeric': [
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterGteOperator', operator: 'gte' },
        { text: 'grid.filterGtOperator', operator: 'gt' },
        { text: 'grid.filterLteOperator', operator: 'lte' },
        { text: 'grid.filterLtOperator', operator: 'lt' },
        { text: 'grid.filterIsNullOperator', operator: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
    ],
    'date': [
        { text: 'grid.filterAfterOperator', operator: 'gt' },
        { text: 'grid.filterBeforeOperator', operator: 'lt' },
        { text: 'grid.filterIsNullOperator', operator: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
    ],
    'boolean': [
        { text: 'grid.filterEqOperator', operator: 'eq' }
    ]
}

// Data
const commonStore = useCommonStore();
let currentColumns = ref([]);
const columnsExpanded = ref(false);
const filterExpanded = ref(false);
const showLockMenu = ref(true);

// Props
const props = defineProps({
    column: Object,
    sortable: [Boolean, Object],
    sort: {
        type: Array,
    },
    filter: Object,
    filterable: Boolean,
    locked: Boolean,
    columns: Array,
    defaultColumns: Array,
});

// Emits
const emit = defineEmits(['contentfocus', 'columnssubmit', 'closemenu', 'expandchange', 'filterchange', 'sortchange', 'lockchange', 'visibilitychange']);

// Computed
const oneVisibleColumn = computed(() => {
    return currentColumns.value.filter((c) => !c.hidden).length === 1;
})

// Lifecycle Methods
onMounted(() => {
    currentColumns.value = props.columns;
    showLockMenu.value = encounterGridConfiguration.showLockColumnMenu(commonStore);
});

// Methods
const handleFocus = function (e) {
    emit('contentfocus', e);
}

const onToggleColumn = function (id) {
    currentColumns.value = currentColumns.value.map((column, idx) => {
        return idx === id ? {
            ...column,
            hidden: !column.hidden
        } : column;
    });
}

const onReset = function (event) {
    event.preventDefault();
    currentColumns.value = props.defaultColumns;
    onSubmit(event);
}

const onSubmit = function (event) {
    if (event) {
        event.preventDefault();
    }
    emit('columnssubmit', currentColumns.value);
    emit('closemenu');
}

const onMenuItemClick = function () {
    const value = !columnsExpanded.value;
    columnsExpanded.value = value;
    filterExpanded.value = value ? false : filterExpanded.value;
}

const onFilterExpandChange = function (value) {
    filterExpanded.value = value;
    columnsExpanded.value = value ? false : columnsExpanded.value;
}

const expandChange = function () {
    emit('expandchange');
}

const closeMenu = function () {
    emit('closemenu');
}

const filterChange = function (newDescriptor, e) {
    emit('filterchange', newDescriptor, e);
}

const sortChange = function (newDescriptor, e) {
    emit('sortchange', newDescriptor);
}

const columnLockUnlock = function (state) {
    emit('lockchange', state);
    emit('closemenu');
}

const columnShowHide = function (state) {
    emit('visibilitychange', state);
}

</script>
  