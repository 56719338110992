import { storeToRefs } from 'pinia'
import { useCommonStore } from '../store/useCommonStore'


export function fetchAPI(url, data, method = 'POST') {
    const store = useCommonStore();
    const headers = new Headers();
    let token = "";
    if (store.storedToken != null && store.userLoggedIn()) {
        token = store.storedToken.token;
    } 

    headers.append('Authorization', `Bearer ${token}`);

    var fetchPackage = { headers, 'method': method, 'body': JSON.stringify(data) };

    if (method === 'GET') {
        fetchPackage = { headers, 'method': method };
    }

    if (method === 'POST') {
        headers.append('Content-Type', 'application/json');
    }

    return fetch(url, fetchPackage);      
}
