<template>
	<div class="topHeader">
		<div class="topHeaderLeft">
			<div @click="onSiteLogoClick">
				<img :src="require('/src/assets/images/strm_logo_icon.png')" class="site-logo" alt="Streamline Health logo" />
			</div>
		</div>
		<div class="topHeaderRight">
			<KButton :theme-color="'primary'" @click="closeThisWindow"><i class="fa-solid fa-xmark fa-fw" />Close</KButton>
		</div>
	</div>
</template>

<style>
    .site-logo {
        cursor: pointer
    }

	.topHeader {
		display: flex;
		min-width: 100%;
	}

	.topHeaderLeft {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-width: 50%;
		padding-left: 20px;
	}

	.topHeaderRight {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		min-width: 50%;
		padding-right: 20px;
	}

	.site-logo {
		height: 50px;
		max-height: 50px;
		padding: 10px 10px 10px 0;
		display: block;
	}
</style>

<script setup>

	import { useRoute } from 'vue-router'
	import { ref, onMounted } from 'vue'
	import { Button as KButton } from '@progress/kendo-vue-buttons';
    import router from '../../router';

	onMounted(() => {

	})

	function closeThisWindow() {
		// Open a 'new window' with _self.
		let this_window = open(location, '_self');
		// That allows us to close this window.
		this_window.close();
		return false;
	}

    function onSiteLogoClick() {
        router.push({ path: '/' })
	}

</script>
