<template>
    <WidgetForm ref="theWidgetForm" :in-edit-mode="widgetEditMode" @closed="fetchData()"></WidgetForm>
    <div id="libraryContainer" class="k-w-full k-h-full">
        <div id="libraryHeader" class="k-text-left">
            <KButton class="k-float-right k-button-noborder" :size="'small'" :theme-color="'info'" :fill-mode="'outline'"
                @click="closeWidgetLibrary()"><i class="fa-solid fa-close" /></KButton>
            <h3>Widget Library</h3>
        </div>
        <div id="libraryActions">
            <div id="topButtons" class="k-text-right" v-show="isAdmin">
                <KButton :theme-color="'primary'" @click="openWidgetForm(false, null)"><i
                        class="fa-solid fa-plus fa-fw" />Create Widget</KButton>
            </div>
            <div id="filterDropDown" class="k-text-left k-justify-content-start k-align-items-center" v-show="displayFilters">
                <KMultiSelect :id="'filterMultiSelect'" :style="{ width: '100%' }" :data-items="userFilters" :text-field="'name'"
                    :data-item-key="'id'" label="Filter by role(s)..." v-model="filters" 
                    :popup-settings="{ className: 'dropDownWidthFix' }" @change="handleFilterChange">
                </KMultiSelect>
            </div>
            <div v-if="loading" class="k-text-center">
                <KLoader :type="'infinite-spinner'" />
            </div>
            <div id="widgetLibrary" class="v-w-full" v-if="widgetsInLibrary">
                <div v-for="widget in widgetsInLibrary" class="widgetItem" :key="widget.id" :id="'linkWidget' + widget.id">
                    <div>
                        <div class="k-float-right k-justify-content-end k-align-items-center buttonSpacers">
                            <KButton :theme-color="'info'" :size="'small'" :fill-mode="'outline'"
                                     @click="includeWidget(widget)">Add</KButton>
                            <KButton class="k-button-noborder" :theme-color="'info'" :size="'small'" :fill-mode="'outline'"
                                     @click="openWidgetForm(true, widget)" v-show="isAdmin">
                                <i class="fa-solid fa-pen" />
                            </KButton>
                        </div>
                        <div class="widgetItemTitle k-text-left k-align-items-center">
                            <i class="fa-regular fa-hashtag fa-fw" title="Number Card"
                                v-if="widget.widgetType.id === 'numberbox'"></i>
                            <i class="fa-light fa-table fa-fw" title="Encounter List"
                                v-if="widget.widgetType.id === 'encounterlist'"></i>
                            <span class="widgetItemTitleText" :title="widget.widgetName">
                                {{ widget.widgetName }}
                            </span>
                        </div>
                    </div>
                    <div class="widgetItemDescription">
                        <div class="k-float-right k-justify-content-end k-align-items-center" v-bind:class="[isAdmin ? 'widgetItemInfoAdmin' : 'widgetItemInfoNonAdmin']">
                            <span class="k-text-primary">
                                <i class="fa-solid fa-people-group k-text-secondary" title="Public Widget"
                                   v-if="widget.isPrivate === false"></i>
                                <i class="fa-solid fa-user k-text-warning" title="Private Widget"
                                   v-if="widget.isPrivate === true"></i>
                            </span>
                        </div>
                        <div class="widgetDescriptionText k-text-left k-align-items-center" :title="widget.description">
                            {{ widget.description + '&nbsp;' }}
                            <!-- &nbsp; used to guarantee the string renders line spacing if description happens to be blank. -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
/* The side navigation menu */
#libraryContainer {
    z-index: 1000;
    /*Stay on top */
    top: 0;
    /* Stay at the top */
    left: 0;
    float: right;
    overflow-x: hidden;
    /* Disable horizontal scroll */
}

#libraryHeader {
    padding: 10px;
    background-color: var(--purple-100);
}

#libraryActions {
    clear: both;
    padding: 10px;
}

#topButtons,
#filterDropDown {
    margin-bottom: 10px;
}

.widgetItem {
    width: 100%;
    background-color: white;
    border: 1px solid var(--violet-default);
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.widgetItemTitle {
    padding-top: 3px;
    cursor: default;
    font-size: 17px;
    font-weight: bold;
    color: var(--violet-default);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-x: clip;
}

.widgetItemDescription {
    clear: both;
}

.widgetItemDescriptionAdmin {

}

.widgetDescriptionText {
    cursor: default;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-x: clip;
}

.widgetItemInfoAdmin {
    margin-right: 5px;
}

.widgetItemInfoNonAdmin {
    margin-right: 2px;
}

.vizientIconAdmin {
    margin: 0 26px;
}

.vizientIconNonAdmin {
    margin: 0 5px;
}
</style>

<script setup>
import { ref, onMounted, provide, defineProps, defineEmits } from 'vue';
import { fetchAPI } from '../../modules/fetchAPI'
import { Button as KButton } from '@progress/kendo-vue-buttons';
import { DropDownButton as KDropDownButton } from "@progress/kendo-vue-buttons";
import { MultiSelect as KMultiSelect } from "@progress/kendo-vue-dropdowns";
import WidgetForm from "./WidgetForm.vue";
import { Popup as KendoPopup } from '@progress/kendo-vue-popup';
import { Menu as KendoMenu } from '@progress/kendo-vue-layout';
import { useCommonStore } from '../../store/useCommonStore';

// Props
const props = defineProps([
    'widgetType' // myevaluator, vizient
]);

const emit = defineEmits(['closeWidgetLibrary', 'includeWidget']);
const showWidgetForm = ref(false);
provide('showWidgetForm', showWidgetForm);

const theWidgetForm = ref();

const commonStore = useCommonStore();
const isAdmin = ref(commonStore.isAdmin);
const isServiceAdmin = ref(commonStore.isServiceAdmin);
const userRoles = ref(commonStore.userRoles);

const widgetRoles = ref([]); // Roles specified for widget use.

const allWidgets = ref([]); // All possible widgets.
const widgetsInLibrary = ref([]); // The widgets to display in the library.

const filters = ref([]); // Filters as defined by the filter dropdown selections.
const userFilters = ref([]); // Filters as defined by the user's roles.
const displayFilters = ref(true); // Boolean to determine whether or not the filter dropdown is displayed.

let widgetEditMode = ref(false);

const loading = ref(true);

onMounted(() => {
    // Disable the filter multiselect input field to prevent typing.
    document.getElementById('filterMultiSelect').readOnly = true;
    fetchData();
})

let fetchData = () => {
    var apiUrl = "api/Widget/GetWidgets/" + (props.widgetType == 'vizient' ? "true" : "false");
    fetchAPI(apiUrl, "", "GET")
        .then(r => r.json())
        .then(json => {
            allWidgets.value = json;
            widgetsInLibrary.value = json;
        })
        // Must wait for data to load before loading roles and doing logic based on both data and roles.
        .then(fetchRoles)
        .then(filterWidgets);
}

let fetchRoles = () => {
    fetchAPI('api/Widget/GetWidgetRoles', "", "GET")
        .then(r => r.json())
        .then(json => {
            widgetRoles.value = json;
            displayFilters.value = false;

            // If has the admin role...
            if (isAdmin.value) {
                // Use all widgets and display the filter dropdown.
                userFilters.value = widgetRoles.value.slice();
                displayFilters.value = true;
            }
            else if (isServiceAdmin.value) {
                // Use all widgets except 'admin' and display the filter dropdown.
                userFilters.value = widgetRoles.value.slice().filter(f => f.name !== 'ADMIN');
                //userFilters.value = userFilters.value.filter(f => f.toLowerCase() !== 'admin');
                displayFilters.value = true;
                // Filter the widgets by the user's roles.
                filterWidgets();
                // Display the filters.
                displayFilters.value = true;
            }
            else {
                // Limit widget roles to this user's roles.
                userRoles.value = userRoles.value.map(function (x) { return x.toUpperCase(); });
                widgetRoles.value.forEach((widgetRole) => {
                    if (userRoles.value.indexOf(widgetRole.name) > -1) {
                        userFilters.value.push(widgetRole);
                    }
                });
                // Filter the widgets by the user's roles.
                filterWidgets();
                // If the user has more than one role, display the filters.
                if (userFilters.value.length > 1) {
                    displayFilters.value = true;
                }
            }
            loading.value = false;
        });
}

function closeWidgetLibrary() {
    emit('closeWidgetLibrary');
}

function includeWidget(widget) {
    emit('includeWidget', widget);
}

const openWidgetForm = (editMode, thisWidget) => {
    widgetEditMode.value = Boolean(editMode);
    theWidgetForm.value.loadWidget(thisWidget);
    showWidgetForm.value = true;
}

function handleFilterChange(event) {
    filters.value = event.target.value;
    filterWidgets();
}

function filterWidgets() {
    // If there are no filters or user role filters, display all widgets.
    if ((isAdmin.value && filters.value.length === 0)
        || (filters.value.length === 0 && userFilters.value.length === 0)) {
        widgetsInLibrary.value = allWidgets.value.slice();
        return;
    }

    // There are either filters selected or user role filters, so filter the widgets.
    let exit = false;
    let filtersToUse = [];
    if (filters.value.length > 0) {
        filtersToUse.value = filters.value.slice();
    }
    else {
        filtersToUse.value = userFilters.value.slice();
    }

    widgetsInLibrary.value = [];

    // For all widgets...
    allWidgets.value.forEach((widget, index) => {
        if (!widget.isPrivate) {
            exit = false;
            // For each filter to use...
            filtersToUse.value.every((filterRole, rindex) => {
                if (widget.widgetRoles.length > 0) {
                    widget.widgetRoles.every((widgetRole, wrindex) => {
                        // If this widget's role matches a filter...
                        if (widgetRole.roleId === filterRole.id) {
                            // Add the widget to the list of widgets to display in the library.
                            widgetsInLibrary.value.push(widget);
                            exit = true; // Used to exit the outer every loop.
                            return false;
                        }
                        return true;
                    });
                }
                if (exit) return false; // Exit this every loop.
                return true;
            });
        }
    });
}
</script>
