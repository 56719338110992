<template>
    <div v-show="showFilterIcon">
        <div class="iconFilter">
            <i class="fa-solid fa-sliders" ref="theFilterIcon" @click="openFilter" />
            <div class="iconFilterHover">
                {{ filterHoverText }}
            </div>
        </div>
        <div :class="'filterMultiSelectDiv'">
            <KMultiSelectTree ref="theMultiSelectTree" :class="'filterMultiSelect'" :dataItems="treeData"
                v-model="selectedNodes" :placeholder="'Select clients / facilities...'" :dataItemKey="dataItemKey"
                :checkField="checkField" :textField="textField" :checkIndeterminateField="checkIndeterminateField"
                :subItemsField="subItemsField" :opened="filterOpen" :expandField="expandField" :filterable="true"
                :onBlur="closeFilter" @filterchange="onFilterChange" @expandchange="onExpandChange" :label="' '" />
        </div>
    </div>
</template>

<style>
.iconFilter {
    font-size: 28px;
    color: var(--violet-default);
    margin: 0 0 0 20px;
    display: inline-block;
    cursor: pointer;
}

.filterMultiSelectDiv {
    right: 0;
    display: flex;
    position: absolute;
    z-index: 1;
}

.filterMultiSelect {
    margin-top: -15px;
    width: 400px;
}

.k-multiselecttree.k-input {
    height: 0;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
}

.k-multiselecttree-popup.k-popup {
    max-height: calc(100vh - 200px);
}

.iconFilter .iconFilterHover {
    visibility: hidden;
    width: 400px;
    color: var(--violet-default);
    background-color: #fff;
    border: 1px solid var(--violet-default);
    max-height: calc(100vh - 133px);
    overflow: hidden;
    text-align: left;
    border-radius: 5px;
    padding: 10px 15px;
    position: absolute;
    z-index: 1;
    font-size: 14px;
    top: 65px;
    right: 0;
    opacity: 0;
    transition: opacity 0.1s;
    box-shadow: 0px 2px 3px 4px rgba(37, 55, 70, 0.1);
    /* box-shadow: (--kendo-elevation-8, 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12)); */
}

.iconFilter:hover .iconFilterHover {
    visibility: visible;
    opacity: 1;
}
</style>

<script setup>
import { ref, computed, onMounted, defineProps, defineEmits } from "vue";
import { MultiSelectTree as KMultiSelectTree } from "@progress/kendo-vue-dropdowns";
import { processMultiSelectTreeData, expandedState } from "../../modules/multiselecttree-data-operations";
import { fetchAPI } from "../../modules/fetchAPI";

// Props
const props = defineProps([
    'widgetType' // myevaluator, vizient
]);

const theMultiSelectTree = ref(null);
const filterOpen = ref(false);
const filterJustClosed = ref(false);
const filterHoverText = ref('Filter widgets by access level');
const theFilterIcon = ref(null);
const showFilterIcon = ref(true);

const dataItemKey = "nodeId";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "nodeText";

const selectedNodes = ref([]);
const expanded = ref([]);
const filter = ref([]);

let dataItems = [];

const fields = {
    dataItemKey,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField,
};

// Emits
const emit = defineEmits(['filterchange']);

onMounted(() => {

    var apiUrl = "api/UserLayout/GetUserClientsAndFacilities/" + (props.widgetType == 'vizient' ? "true" : "false");
    fetchAPI(apiUrl, "", "GET")
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            dataItems = data.nodes;
            selectedNodes.value = data.selectedNodes;

            // If the user has access to only one facility, don't show this filter.
            if (numUserFacilities(data.nodes) < 2) {
                showFilterIcon.value = false;
            }
            else {
                // Expand the 'Select all' item and the first client item.
                expanded.value.push(0); // Select all
                expanded.value.push(dataItems[0].items[0].nodeId); // First client.

                // Set the filter hover text.
                if (selectedNodes.value[0].nodeId !== 0) {
                    // Select All is not selected, so set the hover text.
                    buildHoverText();
                }
            }

        })
        .catch(error => console.error('There was an error!', error));
});

const treeData = computed(() => {
    return processMultiSelectTreeData(dataItems, { expanded: expanded.value, filter: filter.value, value: selectedNodes.value, ...fields });
});

const numUserFacilities = (nodes) => {

    // Check to see if the user has access to only one facility.
    var numFacilities = 0;
    var topNode = treeData.value[0];
    // For each client...
    for (var c = 0; c < topNode.items.length; c++) {
        // Count the facilities.
        numFacilities += topNode.items[c].items.length;
    }
    return numFacilities;
}

const closeFilter = () => {
    if (filterOpen.value) {
        // Save the filter settings.
        filterSave();
        filterOpen.value = false;
    }
    // Set filterJustClosed to true, which stops an onBlur click directly on the filter icon from immediately re-opening the filter.
    filterJustClosed.value = true;
    setTimeout(() => {
        // After a short delay, set filterJustClosed to false so that it can be re-opened.
        filterJustClosed.value = false;
    }, 250);

}

const openFilter = () => {
    // If the filter was just closed by the onBlur, don't immediately re-open it with a click on the filter icon.
    if (filterJustClosed.value) {
        filterJustClosed.value = false;
        return;
    }
    if (!filterOpen.value) {
        console.log('openFilter');
        filterOpen.value = true;
        // A slight delay is required to get the dropdown in place.
        setTimeout(() => {
            // If opened, set the focus on the first checkbox in the dropdown so that a blur can close it.
            var thePopup = document.getElementsByClassName('k-multiselecttree-popup')[0]
                .getElementsByClassName('k-treeview')[0]
                .getElementsByClassName('k-treeview-group')[0]
                .getElementsByClassName('k-treeview-item')[0]
                .getElementsByClassName('k-treeview-mid')[0]
                .getElementsByClassName('k-checkbox-wrap')[0]
                .getElementsByClassName('k-checkbox')[0];
            thePopup.focus();
            theFilterIcon.value.disabled = true; // Blur will close the popup, so don't allow a click on the icon that would open it back up.
        }, 100);
    }
}

const filterSave = () => {
    var apiUrl = "api/UserLayout/SaveUserSelectedClientFacilities/";
    var data = {
        selectedNodes: selectedNodes.value,
        IsVizient: props.widgetType == 'vizient' ? "true" : "false"
    }
    fetchAPI(apiUrl, data, "POST")
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            emit('filterchange');
            return true;
        })
        .catch(error => console.error('There was an error!', error));
    buildHoverText();
}

const onExpandChange = (event) => {
    expanded.value = (expandedState(event.item, dataItemKey, expanded.value))
}

const onFilterChange = (event) => {
    filter.value = event.filter;
}

const buildHoverText = () => {

    filterHoverText.value = '';

    // Get all the selected nodes.
    var selectedIds = [];
    for (var s = 0; s < selectedNodes.value.length; s++) {
        selectedIds.push(selectedNodes.value[s].nodeId);
    }

    // If a client or facility is selected, add that client/facility name to the hovertext.
    var topNode = treeData.value[0];
    for (var c = 0; c < topNode.items.length; c++) {
        var clientDisplayed = false;
        // Client node.
        if (selectedIds.includes(topNode.items[c].nodeId)) {
            filterHoverText.value += topNode.items[c].nodeText + ', ';
            clientDisplayed = true;
        }
        // Facility nodes.
        var clientNode = topNode.items[c];
        for (var f = 0; f < clientNode.items.length; f++) {
            if (selectedIds.includes(clientNode.items[f].nodeId)) {
                if (!clientDisplayed) {
                    filterHoverText.value += clientNode.nodeText + ', ';
                    clientDisplayed = true;
                }
                filterHoverText.value += clientNode.items[f].nodeText + ', ';
            }
        }
    }
    // Strip the trailing comma and space.
    if (filterHoverText.value.length > 0) {
        filterHoverText.value = 'Filtered by ' + filterHoverText.value.substring(0, filterHoverText.value.length - 2) + '.';
    }
    else {
        filterHoverText.value = 'Filter widgets by access level';
    }
}

</script>
